// constants
import { LayoutActionTypes } from './constants';

export type LayoutActionType<TPayload> = {
    type:
        | LayoutActionTypes.CHANGE_LAYOUT
        | LayoutActionTypes.CHANGE_LAYOUT_COLOR
        | LayoutActionTypes.CHANGE_LAYOUT_WIDTH
        | LayoutActionTypes.CHANGE_MENU_POSITIONS
        | LayoutActionTypes.CHANGE_SIDEBAR_THEME
        | LayoutActionTypes.CHANGE_SIDEBAR_TYPE
        | LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO
        | LayoutActionTypes.CHANGE_TOPBAR_THEME
        | LayoutActionTypes.SHOW_RIGHT_SIDEBAR
        | LayoutActionTypes.THEME
        | LayoutActionTypes.API_RESPONSE_SUCCESS
        | LayoutActionTypes.API_RESPONSE_ERROR
        | LayoutActionTypes.CHANGE_TOPBAR_COLOR
        | LayoutActionTypes.TOGGLE_FOOTER
        | LayoutActionTypes.TOGGLE_SHORTCUT
        | LayoutActionTypes.TOGGLE_MOBILE_SHORTCUT
        | LayoutActionTypes.HIDE_RIGHT_SIDEBAR;
    payload?: TPayload;
};

export const changeLayout = (layout: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT,
    payload: layout,
});
export const themeCustomize = (layout: string,color: string,width: string,position: string,sidebarTheme: string,sidebarType: string,topbarTheme: string,footerType: string,shortcutbar: string,mobileshortcutbar:'string') => ({
    type: LayoutActionTypes.THEME,
    payload: {layout,color,width,position,sidebarTheme,sidebarType,topbarTheme,footerType,shortcutbar,mobileshortcutbar},
    });

export const layoutResponseSuccess = (actionType: string, data:any) => ({
    type: LayoutActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const layoutResponseError = (actionType: string, error: string) => ({
    type: LayoutActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const changeLayoutColor = (color: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
    payload: color,
});

export const changeLayoutWidth = (width: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
    payload: width,
});

export const changeMenuPositions = (position: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_MENU_POSITIONS,
    payload: position,
});

export const changeSidebarTheme = (sidebarTheme: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
    payload: sidebarTheme,
});

export const changeSidebarType = (sidebarType: string): LayoutActionType<string> => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
    payload: sidebarType,
});

export const toggleSidebarUserInfo = (showSidebarUserInfo: boolean): LayoutActionType<boolean> => ({
    type: LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO,
    payload: showSidebarUserInfo,
});

export const changeTopbarTheme = (color:string)=> {
return({
    type: LayoutActionTypes.CHANGE_TOPBAR_COLOR,
    payload: color,
})}

export const showRightSidebar = (): LayoutActionType<null> => ({
    type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR,
});

export const hideRightSidebar = (): LayoutActionType<null> => ({
    type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR,
});
export const changeFooterType = (scroll:string)=> ({
    type: LayoutActionTypes.TOGGLE_FOOTER,
    payload: scroll, 
});
export const changeShortcutbar = (scroll:string)=> ({
    type: LayoutActionTypes.TOGGLE_SHORTCUT,
    payload: scroll,
});
export const changeMobileShortcutbar = (scroll:string)=> ({
    type: LayoutActionTypes.TOGGLE_MOBILE_SHORTCUT,
    payload: scroll,
});
export const resetTheme = () => ({
    type: LayoutActionTypes.RESET,
    payload:{}
});
