import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
    const baseUrl = '/login';

    return api.create(`${baseUrl}`, params)
        .then(response => {
        //console.log(response.data)
            // Ensure the response structure is as expected
            if (response && response.data) {
                return response.data; // Return the data directly if it exists
            }
            throw new Error('No data returned');
        })
        .catch(error => {
            console.error('API Error:', error);
            throw error; // Rethrow for saga to catch
        });
}
function logout() {
    const baseUrl = '/logout';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
