import React from "react";
import "./MainLoader.css"; // Import the CSS file

const MainLoader = ({ useInfo }) => {
    return (
        <div className="loader-container">
            <div className="animated-loader">
                <span className="logo-lg">
                    {useInfo && (
                        <img
                            src={useInfo?.logopath}
                            alt="Loading Logo"
                            className="pulse-logo"
                        />
                    )}
                </span>
            </div>
        </div>
    );
};

export default MainLoader;
