import { BankActionTypes } from './constants';

const INIT_STATE = {
    addChequeReceipts: null,
    addBanks: null,
    addedBankAcc: null,
    updChequeReceipts: null,
    addChequePayments: null,
    updChequePayments: null,
    addedEncash: null,
    addedChequeTranfer: null,
    addedChequeTranferReturn: null,
    addedRefund: null,
    addedChequePayReturn: null,
    updatedChequePayReturn: null,
    upd_refund: null,
    added_cancel: null,
    updChequeTransfer: null,
    upd_ChequeTransferReturn: null,
    upd_Encash: null,
    loading: false,
    modalLoading: false,
    error: null,
};

const Bank = (state = INIT_STATE, action) => {
    switch (action.type) {
        case BankActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BankActionTypes.Add_CheckReceipt:
                    return {
                        ...state,
                        addChequeReceipts: action.payload,
                    };
                case BankActionTypes.ADD_BANK:
                    return {
                        ...state,
                        addBanks: action.payload,
                        modalLoading: false,
                    };
                case BankActionTypes.ADD_BANK_ACC:
                    return {
                        ...state,
                        addedBankAcc: action.payload,
                        modalLoading: false,
                    };
                case BankActionTypes.UPD_CheckReceipt:
                    return {
                        ...state,
                        updChequeReceipts: action.payload,
                    };
                case BankActionTypes.Add_CheckPayment:
                    return {
                        ...state,
                        addChequePayments: action.payload,
                    };
                case BankActionTypes.Add_ENCASH:
                    return {
                        ...state,
                        addedEncash: action.payload,
                    };
                case BankActionTypes.ADD_CHEQUETRANSFER:
                    return {
                        ...state,
                        addedChequeTranfer: action.payload,
                    };
                case BankActionTypes.ADD_CHEQUETRANSFERRETURN:
                    return {
                        ...state,
                        addedChequeTranferReturn: action.payload,
                    };
                case BankActionTypes.ADD_CHEQUEPAYRETURN:
                    return {
                        ...state,
                        addedChequePayReturn: action.payload,
                    };
                    case BankActionTypes.UPD_CHEQUEPAYRETURN:
                    return {
                        ...state,
                        updatedChequePayReturn: action.payload,
                    };
                case BankActionTypes.Add_REFUND:
                    return {
                        ...state,
                        addedRefund: action.payload,
                    };
                case BankActionTypes.Add_CANCEL:
                    return {
                        ...state,
                        added_cancel: action.payload,
                    };
                case BankActionTypes.UPD_RFUND:
                    return {
                        ...state,
                        upd_refund: action.payload,
                    };
                case BankActionTypes.UPD_CHQUETRANFER:
                    return {
                        ...state,
                        updChequeTransfer: action.payload,
                    };
                case BankActionTypes.UPD_CHQUETRANFERRETURN:
                    return {
                        ...state,
                        upd_ChequeTransferReturn: action.payload,
                    };
                case BankActionTypes.UPD_ENCASH:
                    return {
                        ...state,
                        upd_Encash: action.payload,
                    };
                case BankActionTypes.UPD_CheckPayment:
                    return {
                        ...state,
                        updChequePayments: action.payload,
                    };
                default:
                    return { ...state };
            }
        case BankActionTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                addChequeReceipts: null,
                addBanks: null,
                addedBankAcc: null,
                updChequeReceipts: null,
                addChequePayments: null,
                addedEncash: null,
                addedChequeTranfer: null,
                addedChequeTranferReturn: null,
                addedChequePayReturn: null,
                updatedChequePayReturn:null,
                addedRefund: null,
                added_cancel: null,
                upd_refund: null,
                updChequeTransfer: null,
                upd_ChequeTransferReturn: null,
                upd_Encash: null,
                updChequePayments: null,
                modalLoading: true,
                loading: true,
            };
        case BankActionTypes.Add_CheckReceipt:
            return { ...state, loading: true, addChequeReceipts: null };
        case BankActionTypes.ADD_BANK:
            return { ...state, modalLoading: true, addBanks: null };
        case BankActionTypes.ADD_BANK_ACC:
            return { ...state, modalLoading: true, addedBankAcc: null };
        case BankActionTypes.UPD_CheckReceipt:
            return { ...state, loading: true, updChequeReceipts: null };
        case BankActionTypes.Add_CheckPayment:
            return { ...state, loading: true, addChequePayments: null };
        case BankActionTypes.Add_ENCASH:
            return { ...state, loading: true, addedEncash: null };
        case BankActionTypes.UPD_CHQUETRANFERRETURN:
            return { ...state, loading: true, upd_ChequeTransferReturn: null };
        case BankActionTypes.ADD_CHEQUETRANSFER:
            return { ...state, loading: true, addedChequeTranfer: null };
        case BankActionTypes.ADD_CHEQUETRANSFERRETURN:
            return { ...state, loading: true, addedChequeTranferReturn: null };
        case BankActionTypes.ADD_CHEQUEPAYRETURN:
            return { ...state, loading: true, addedChequePayReturn: null };
            case BankActionTypes.UPD_CHEQUEPAYRETURN:
                return { ...state, loading: true, updatedChequePayReturn: null };
        case BankActionTypes.Add_REFUND:
            return { ...state, loading: true, addedRefund: null };
        case BankActionTypes.Add_CANCEL:
            return { ...state, loading: true, added_cancel: null };
        case BankActionTypes.UPD_RFUND:
            return { ...state, loading: true, upd_refund: null };
        case BankActionTypes.UPD_CHQUETRANFER:
            return { ...state, loading: true, updChequeTransfer: null };
        case BankActionTypes.UPD_ENCASH:
            return { ...state, loading: true, upd_Encash: null };
        case BankActionTypes.UPD_CheckPayment:
            return { ...state, loading: true, updChequePayments: null };
        case BankActionTypes.RESET:
            return {
                ...state,
                loading: false,
                addChequeReceipts: null,
                updChequeReceipts: null,
                addChequePayments: null,
                addedRefund: null,
                added_cancel: null,
                upd_refund: null,
                addedEncash: null,
                addedChequeTranferReturn: null,
                addedChequePayReturn:null,
                updatedChequePayReturn:null,
                addedChequeTranfer: null,
                updChequeTransfer: null,
                upd_ChequeTransferReturn: null,
                upd_Encash: null,
                updChequePayments: null,
                modalLoading: false,
                addBanks: null,
                addedBankAcc: null,
            };
        default:
            return { ...state };
    }
};

export default Bank;
