import { APICore } from './apiCore';

const api = new APICore();

function addItem(params: {
    itemcodetype: string;
    itemcode: string;
    description: string;
    UrduDescription: string;
    itemtype: string;
    categoryid: string;
    brandid: string;
    masterpackingunitid: string;
    unitprice: string;
    price1: string;
    itemcosteach: string;
    notes: string;
    minstocklevel: string;
    codetype: string;
    allowdis: string;
    subcatid: string;
    pcsperpack: string;
    packpermaster: string;
    unitweight: string;
    pcsperpackunitid: string;
    packpermasterunitid: string;
    negativestock: string;
    isservice: string;
    barcode:string;
}) {
    console.log('addItem---');
    const baseUrl = '/add-item';
    return api.create(`${baseUrl}`, params);
}

function addInvUnit(params: { unitname: string }) {
    console.log('addunit it---');
    const baseUrl = '/add-item-unit';
    return api.create(`${baseUrl}`, params);
}
function addItemBrand(params: { name: string }) {
    console.log('add brand it---');
    const baseUrl = '/add-item-brand';
    return api.create(`${baseUrl}`, params);
}
function addItemCode(params: {
    itemcodetype: string;
    setcode: string;
    setnumber: string;
    codetype: string;
    defaultitem: string;
}) {
    const baseUrl = '/update-item-code';
    return api.create(`${baseUrl}`, params);
}

function addOpeningStock(params: { ITDate: string; DetailedDescription: string; osdetdata: object }) {
    console.log('add op st---');
    const baseUrl = '/add-item-trans';
    return api.create(`${baseUrl}`, params);
}

function addStockAdjustment(params: { ITDate: string; DetailedDescription: string; adjdetdata: object }) {
    console.log('add stock adjustment');
    const baseUrl = '/add-adjustment';
    return api.create(`${baseUrl}`, params);
}

function updateStockAdjustment(params: {
    id: string;
    ITDate: string;
    DetailedDescription: string;
    adjdetdata: object;
}) {
    console.log(params.id, 'upd stock Adjustment---');
    const baseUrl = `/update-adjustment/${params.id}`;
    return api.create(`${baseUrl}`, params);
}

function addStockIn(params: { ITDate: string; DetailedDescription: string; adjdetdata: object }) {
    console.log('add stock in');
    const baseUrl = '/add-stockin';
    return api.create(`${baseUrl}`, params);
}

function updateStockIn(params: { id: string; ITDate: string; DetailedDescription: string; adjdetdata: object }) {
    console.log(params.id, 'upd stock in---');
    const baseUrl = `/update-stockin/${params.id}`;
    return api.create(`${baseUrl}`, params);
}

function addStockOut(params: { ITDate: string; DetailedDescription: string; adjdetdata: object }) {
    console.log('add stock out');
    const baseUrl = '/add-stockout';
    return api.create(`${baseUrl}`, params);
}

function updateStockOut(params: { id: string; ITDate: string; DetailedDescription: string; adjdetdata: object }) {
    console.log(params.id, 'upd stock out---');
    const baseUrl = `/update-stockout/${params.id}`;
    return api.create(`${baseUrl}`, params);
}

function addStockTransfer(params: { ITDate: string; DetailedDescription: string; transdetdata: object }) {
    console.log('add stock transfer');
    const baseUrl = '/add-transfer';
    return api.create(`${baseUrl}`, params);
}

function updateStockTrans(params: { id: string; ITDate: string; DetailedDescription: string; transdetdata: object }) {
    console.log(params.id, 'upd stock transfer---');
    const baseUrl = `/update-transfer/${params.id}`;
    return api.create(`${baseUrl}`, params);
}

function multipleItems_api(params) {
    const baseUrl = '/add-multiple-items';
    return api.create(`${baseUrl}`, params);
}
function updateOpeningStocks(params: { id: string; ITDate: string; DetailedDescription: string; osdetdata: object }) {
    console.log(params.id, 'upd op st---');
    const baseUrl = `/update-opening-stock/${params.id}`;
    return api.create(`${baseUrl}`, params);
}

function addItemType(params: { type: string }) {
    console.log('add item Type it---');
    const baseUrl = '/add-item-type';
    return api.create(`${baseUrl}`, params);
}
function addItemCategory(params: { name: string }) {
    console.log('add category it---');
    const baseUrl = '/add-item-cat';
    return api.create(`${baseUrl}`, params);
}
function subCategoryApi(params: { catid: string; name: string }) {
    console.log('add category it---');
    const baseUrl = '/add-item-subcat';
    return api.create(`${baseUrl}`, params);
}

function FormLocManage(params) {
    console.log('mangee form loxation---');
    console.log(params);
    const baseUrl = `/add-itemlocation`;
    return api.create(`${baseUrl}`, params);
}
function updmultipleItems_api(params) {
    const baseUrl = `/update-multiple-items/${params.id}`;
    return api.update(`${baseUrl}`, params);
}

function updItem(params: {
    id: string;
    itemcodetype: string;
    itemcode: string;
    description: string;
    UrduDescription: string;
    itemtype: string;
    categoryid: string;
    brandid: string;
    masterpackingunitid: string;
    unitprice: string;
    price1: string;
    itemcosteach: string;
    notes: string;
    minstocklevel: string;
    codetype: string;
    allowdis: string;
    subcatid: string;
    pcsperpack: string;
    packpermaster: string;
    unitweight: string;
    pcsperpackunitid: string;
    packpermasterunitid: string;
    image: any;
    negativestock: string;
    isservice: string;
    barcode:string;
}) {
    console.log('updateItem---');
    console.log(params.id + ' mngt');
    const baseUrl = `/update-item/` + params.id;
    return api.create(`${baseUrl}`, params);
}

export {
    addStockTransfer,
    updateStockTrans,
    updmultipleItems_api,
    multipleItems_api,
    addItem,
    updItem,
    addInvUnit,
    subCategoryApi,
    addItemBrand,
    addItemType,
    addItemCategory,
    FormLocManage,
    addOpeningStock,
    addItemCode,
    updateOpeningStocks,
    addStockAdjustment,
    updateStockAdjustment,
    addStockIn,
    updateStockIn,
    addStockOut,
    updateStockOut,
};
