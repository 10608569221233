import React, { Suspense, useEffect, useState } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

// hooks
import { useRedux, useViewport } from '../hooks/';
import moment from 'moment';
// constants
import { LayoutTypes, SideBarTypes } from '../constants';
import axios from 'axios';
// utils
import { changeBodyAttribute } from '../utils';
import MainLoader from '../components/MainLoader';
import {
    changeLayout,
    changeLayoutColor,
    changeLayoutWidth,
    changeMenuPositions,
    changeSidebarTheme,
    changeSidebarType,
    toggleSidebarUserInfo,
    changeTopbarTheme,
    themeCustomize,
    changeFooterType,
    changeShortcutbar,
    changeMobileShortcutbar,
} from '../redux/actions';
import ShortcutLinks from '../pages/dashboards/DashBoard1/ShortcutLinks';
const Topbar = React.lazy(() => import('./Topbar/'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const RightSidebar = React.lazy(() => import('./RightSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""></div>;

const VerticalLayout = () => {
    const { dispatch, appSelector } = useRedux();
    let [themeItems, setThemeItems] = useState(null);
    const { width } = useViewport();
    const {
        layoutColor,
        layoutWidth,
        menuPosition,
        leftSideBarTheme,
        leftSideBarType,
        showSidebarUserInfo,
        toggleFooter,
        shortcutbar,
        shortcutbarmobile,
        topbarTheme,
        pageTitle,
    } = appSelector((state) => ({
        layoutColor: state.Layout.layoutColor,
        layoutWidth: state.Layout.layoutWidth,
        menuPosition: state.Layout.menuPosition,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftSideBarType: state.Layout.leftSideBarType,
        showSidebarUserInfo: state.Layout.showSidebarUserInfo,
        topbarTheme: state.Layout.topbarTheme,
        toggleFooter: state.Layout.toggleFooter,
        shortcutbar: state.Layout.shortcutbar,
        shortcutbarmobile: state.Layout.shortcutbarmobile,
        pageTitle: state.PageTitle.pageTitle,
    }));

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    /*
  layout defaults
  */
    useEffect(() => {
        changeBodyAttribute('data-layout-mode', LayoutTypes.LAYOUT_VERTICAL);
    }, []);

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);
    useEffect(() => {
        changeBodyAttribute('scroll-footer', toggleFooter);
    }, [toggleFooter]);
    useEffect(() => {
        changeBodyAttribute('shortcut-bar', shortcutbar);
    }, [shortcutbar]);
    useEffect(() => {
        changeBodyAttribute('shortcutmobile-bar', shortcutbarmobile);
    }, [shortcutbarmobile]);
    useEffect(() => {
        changeBodyAttribute('data-layout-size', layoutWidth);
    }, [layoutWidth]);
    useEffect(() => {
        changeBodyAttribute('data-leftbar-position', menuPosition);
    }, [menuPosition]);
    useEffect(() => {
        changeBodyAttribute('data-leftbar-color', leftSideBarTheme);
    }, [leftSideBarTheme]);
    useEffect(() => {
        changeBodyAttribute('data-leftbar-size', width < 786 ? 'default' : leftSideBarType);
    }, [leftSideBarType, width]);
    useEffect(() => {
        changeBodyAttribute('data-topbar-color', topbarTheme);
    }, [topbarTheme]);
    useEffect(() => {
        changeBodyAttribute('data-sidebar-user', showSidebarUserInfo);
    }, [showSidebarUserInfo]);
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 1000);
        handleResize(); // Call once to set initial value
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };
    const isCondensed: boolean = leftSideBarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;
    const [companyInfo, setCompanyInfo] = useState(null);
    const [weather, setWeather] = useState(null);
    const [branchName, setBranchName] = useState('');
    const [weatherIcon, setWeatherIcon] = useState(null);
    const [currentTime, setCurrentTime] = useState(moment().format('HH:mm:ss'));
    const [menusItems, setMenuItems] = useState([]);
    const [loadings, setLoading] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment().format('HH:mm:ss'));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        getWeather();
        let user = sessionStorage.getItem('comp_Info');
        let covertJson = JSON.parse(user);
        setCompanyInfo(covertJson);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment().format('HH:mm:ss'));
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    const getWeather = async () => {
        await axios
            .get('/get-weather')
            .then((res) => {
                //console.log(res.data)
                setWeather(res.data);
                if (res.data.weather[0].main === 'Clear') {
                    setWeatherIcon('mdi mdi-weather-partly-cloudy');
                } else if (res.data.weather[0].main === 'Clouds') {
                    setWeatherIcon('mdi mdi-weather-cloudy');
                }
            })
            .catch((error) => error);
    };
    const [time, setTime] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    useEffect(() => {
        const timerInterval = setInterval(updateTimer, 1000);
        return () => {
            clearInterval(timerInterval);
        };
    }, []);
    const updateTimer = () => {
        setTime((prevTime) => {
            const { hours, minutes, seconds } = prevTime;
            if (seconds < 59) {
                return { ...prevTime, seconds: seconds + 1 };
            } else if (minutes < 59) {
                return { ...prevTime, seconds: 0, minutes: minutes + 1 };
            } else {
                return { seconds: 0, minutes: 0, hours: hours + 1 };
            }
        });
    };
    useEffect(() => {
        setLoading(true);
        getMenusData();
        getThemeData();
    }, []);
    const getMenusData = async () => {
        await axios.get('/get-menus').then((res) => {
            setMenuItems(res.data.menus);
        });
    };
    const getThemeData = async () => {
        await axios.get('/get-theme-customization').then((res) => {
            setThemeItems(res.data.customization);
            dispatch(changeLayout(res.data.customization.layout));
            dispatch(changeLayoutColor(res.data.customization.colorscheme));
            dispatch(changeLayoutWidth(res.data.customization.width));
            dispatch(changeMenuPositions(res.data.customization.menuposition));
            dispatch(changeSidebarTheme(res.data.customization.sidebarcolor));
            dispatch(changeSidebarType(res.data.customization.sidebarsize));
            dispatch(changeTopbarTheme(res.data.customization.topbarTheme));
            dispatch(changeFooterType(res.data.customization.footerType));
            dispatch(changeShortcutbar(res.data.customization.shortcutbar));
            dispatch(changeMobileShortcutbar(res.data.customization.mobileshortcutbar));
            getBranch();
        });
    };
    const getBranch = async () => {
        axios.get('/get-branch-information').then((res) => {
            setBranchName(res.data?.name);
            setLoading(false);
        });
    };
    const [dashbaordRights, setDashbaordRights] = useState([]);
    const [getMenu, setMenu] = useState([]);
    const [getRights, setRights] = useState(null);
    const [getIconType, setIconType] = useState(null);
    const [getMobileRights, setMobileRights] = useState(null);
    const [mobileShortcut, setMobileShortcut] = useState(null);
    const getRightsData = async () => {
        let user = sessionStorage.getItem('user_Information');
        let id = JSON.parse(user)?.id;
        await axios.get(`/get-shortcutbar-rights/${id}`).then((res) => {
            setRights(res.data.desktopshortcutRights);
            setIconType(res.data.icontype);
            setMobileShortcut(res.data.icontype);
            setMobileRights(res.data.mobileshortcutRights);
        });
    };
    // const getDashboardRighta = async (id) => {
    //     await axios.get(`/get-shortcutbar-rights/${id}`).then((res) => {
    //         const selectedData = {};
    //         const response = res.data.shortcutRights || [];

    //         console.log(response);

    //         response.forEach((right) => {
    //             selectedData['allow-' + right.value] = true;
    //         });

    //         setSelectedCheckboxes(selectedData);
    //     });
    // };

    //  const buttonItems = [

    //    {
    //        tooltip: "Add Invoice",
    //        url: "si/add",
    //        name: 'SI',
    //        icon: "ti-receipt",
    //        className: "text-success",
    //        size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Add POS Invoice",
    //        url: "customer/countersale",
    //        name: 'POS',
    //        icon: "ti-receipt",
    //        className: "text-success",
    //        size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Add Customer Receipt",
    //        url: "pay-Receipt/add",
    //        name: 'PMT',
    //        icon: "fas fa-hand-holding-usd",
    //         className: "text-success",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Customer Credit Note",
    //        url: "customer/creditnote",
    //        name: 'CCN',
    //        icon: "fas fa-file-invoice-dollar",
    //         className: "text-success",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Customer Debit Note",
    //        url: "customer/debitnote",
    //        name: 'CDN',
    //        icon: "fas fa-file-invoice-dollar",
    //         className: "text-success",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Customer Ledger",
    //        url: "customers/ledger",
    //        icon: "fe-file-text",
    //        name: 'CL',
    //         className: "text-success",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Customer Balance Report",
    //        url: "sales-reports/customersbalancerep",
    //        icon: "fas fa-balance-scale-right",
    //        name: 'CBR',
    //         className: "text-success",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Customer Sales and Recovery",
    //        url: "sales-analysis/salesrecovery",
    //        icon: "fe-file-text",
    //        name: 'CSR',
    //         className: "text-success",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Stock Report",
    //        url: "inventory-reports/stockReport",
    //        icon: "fe-file-text",
    //        name: 'CBR',
    //         className: "text-success",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Item Ledger",
    //        url: "inventory-reports/ItemLedger",
    //        icon: "fe-file-text",
    //        name: 'CSR',
    //         className: "text-success",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Add Purchase",
    //        url: "purchase/addPurchase",
    //        name: 'PI',
    //        icon: "fe-shopping-cart",
    //        className: "text-warning ",
    //        size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Add Supplier Payment",
    //        url: "supplier-payment/add",
    //        icon: "fas fa-donate",
    //        name: 'SP',
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Add Tools Expense",
    //        url: "services/addtoolsexpense",
    //        name: 'TEV',
    //        icon: "fas fa-tools",
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },

    //     {
    //        tooltip: "Add Services Bill",
    //        url: "supplier/addservicesbill",
    //        icon: "fas fa-donate",
    //        name: 'SSB',
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Supplier Credit Note",
    //        url: "supplier/creditnote",
    //        name: 'SCN',
    //        icon: "fas fa-file-invoice-dollar",
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Supplier Debit Note",
    //        url: "supplier/debitnote",
    //        name: 'SDN',
    //        icon: "fas fa-file-invoice-dollar",
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Supplier Ledger",
    //        url: "suppliers/ledger",
    //        icon: "fe-file-text",
    //        name: 'SL',
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Supplier Balance Report",
    //        url: "supplier-reports/supplierbalancerep",
    //        icon: "fas fa-balance-scale-left",
    //        name: 'SBR',
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Supplier Purchase and Payment",
    //        url: "purchase-analysis/purchasepayment",
    //        icon: "fe-file-text",
    //        name: 'SPP',
    //         className: "text-warning ",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Cash Payment",
    //        url: "ledger/cashpayment/add",
    //        icon: "dripicons-wallet",
    //        name: 'CPV',
    //         className: "text-info mt-1",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Cash Receipt",
    //        url: "ledger/cashreceipt/add",
    //        icon: "dripicons-wallet",
    //        name: 'CRV',
    //         className: "text-info mt-1",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Journal Voucher",
    //        url: "journalvoucher/add",
    //        icon: "dripicons-wallet",
    //        name: 'JV',
    //         className: "text-info mt-1",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Account Ledger",
    //        url: "ledger/generalledger",
    //        icon: "fe-file-text",
    //        name: 'AL',
    //         className: "text-info",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Account Balance Summary",
    //        url: "ledger/account/summary",
    //        icon: "fas fa-balance-scale",
    //        name: 'ABS',
    //         className: "text-info",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Day Book",
    //        url: "ledger/daybook",
    //        icon: "mdi mdi-book-open-page-variant-outline",
    //        name: 'DB',
    //         className: "text-info",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Cash Book",
    //        url: "ledger/cashbook",
    //        icon: "mdi mdi-book-open-page-variant-outline",
    //        name: 'CB',
    //         className: "text-info",
    //         size: "1.2rem",
    //    },

    //    {
    //        tooltip: "Add Items",
    //        url: "items/add",
    //        icon: "fe-layers",
    //        name: 'AC',
    //         className: "text-danger",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Add Customers",
    //        url: "customers/add",
    //        icon: "fas fa-users",
    //        name: 'AC',
    //         className: "text-danger",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Add Suppliers",
    //        url: "suppliers/add",
    //        icon: "fas fa-user-friends",
    //        name: 'AS',
    //         className: "text-danger",
    //         size: "1.2rem",
    //    },
    //    {
    //        tooltip: "Add Accounts",
    //        url: "ledger/detailaccount",
    //        icon: "fas fa-list",
    //        name: 'JV',
    //         className: "text-danger",
    //         size: "1.2rem",
    //    },
    // ];

    useEffect(() => {
        getDashboardRights();
        getMenuData();
        getRightsData();
    }, []);
    const getDashboardRights = async () => {
        let user = sessionStorage.getItem('user_Information');
        let id = JSON.parse(user)?.id;
        await axios.get(`/get-dashboard-rights/${id}`).then((res) => {
            setDashbaordRights(res.data.dashboardRights);
        });
    };
    const getMenuData = async () => {
        await axios.get('/get-menus').then((res) => {
            setMenu(res.data.menus);
        });
    };
    /*
      const filteredButtonItems = buttonItems.filter((button) =>
        getMenu.some((route) =>route.children?.some((menu) =>menu.children?.some((main) =>main.url?.includes(button.url))))
      );
      
      */

    // const filteredButtonItems = getRights.filter((button) =>
    //   getMenu.some((route) =>
    //     route.children?.some((menu) =>
    //       // Check if button.url is in menu.url or any of the sub-children (main.url)
    //       (menu.url?.includes(button.url)) ||
    //       menu.children?.some((main) => main.url?.includes(button.url))
    //     )
    //   )
    // );
    // const filteredButtonItems = width ? getMobileRights() : getRights();
    if (!loadings)
        return (
            <>
                <div id="wrapper">
                    <Suspense fallback={loading()}>
                        <Topbar openLeftMenuCallBack={openMenu} branchName={branchName} />
                    </Suspense>

                    <Suspense fallback={loading()}>
                        <LeftSidebar isCondensed={isCondensed} menusItems={menusItems} />
                    </Suspense>
                    <div className="content-page">
                        <div className="content">
                            <Container fluid>
                                {isSmallScreen ? (
                                    <Row>
                                        <Col xs={12}>
                                            <div className="p-1">
                                                <h4 className="page-title">
                                                    {pageTitle.title != undefined && pageTitle.title != null
                                                        ? pageTitle.title
                                                        : branchName}
                                                </h4>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    ''
                                )}

                                {shortcutbar === 'show' && width > 786 && (
                                    <ShortcutLinks
                                        dashbaordRights={dashbaordRights}
                                        filteredButtonItems={getRights}
                                        iconType={getIconType}
                                    />
                                )}

                                {shortcutbarmobile === 'show' && width <= 786 && (
                                    <ShortcutLinks
                                        dashbaordRights={dashbaordRights}
                                        filteredButtonItems={getMobileRights}
                                        iconType={getIconType}
                                    />
                                )}

                                {/* <ShortcutLinks dashbaordRights={dashbaordRights} filteredButtonItems={getRights} /> */}

                                <Outlet />
                            </Container>
                        </div>

                        <Suspense fallback={loading()}>
                            <Footer />
                        </Suspense>
                    </div>
                </div>

                <Suspense fallback={loading()}>
                    <RightSidebar themeItems={themeItems} getThemeData={getThemeData} />
                </Suspense>
            </>
        );
    return (
        <>
            <MainLoader useInfo={companyInfo} />
        </>
    );
};

export default VerticalLayout;
