import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Itmmngt from './itmmngt/reducers';
import Customers from './customer/reducers';
import Supplier from './supplier/reducers';
import Sales from './sales/reducers';
import Layout from './layout/reducers';
import PageTitle from './pageTitle/reducers';
import Admin from './admin/reducers';
import Apps from './app/reducers';
import Ledgers from './Ledger/reducers';
import Purchase from './purchase/reducers';
import Employee from './employee/reducers';
import Themes from './layout/theme_reducer';
import Bank from './bank/reducers';
import Services from './Services/reducers';
import Processing from './Processing/reducers';

export default combineReducers({
    Auth,
    Layout,
    PageTitle,
    Itmmngt,
    Sales,Themes,
    Customers,
    Purchase,Employee,
    Supplier,Admin,Apps,Ledgers,Bank,Services,Processing,
});
