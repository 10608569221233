import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// helpers
import { addBOM_api, manageProcess_Api, manufacProcess_Api,addPRD_api ,editPRD_api} from '../../helpers';
// actions
import { processApiResponseSuccess, processApiResponseError } from './actions';
// constants
import { ProcessActionTypes } from './constants';
type EmpData = {
    payload: {
        entrydate: string;
        amount: string;
        itemid: string;
        costnumber: string;
        quantity: string;
        costdetdata: object;
    };
    type: string;
};
type PRD_Data = {
    payload: {
        id:string;
        entrydate: string;
        description: string;
        amount: string;
        peamount: string;
        productiondata: object;
    };
    type: string;
};
type mangeProcess_data = {
    payload: {
        name: string;
    };
    type: string;
};
type manufac_data = {
    payload: {
        proecessdata: object;
    };
    type: string;
};
const api = new APICore();
// const [getRes , setRes] = useState()
export const setResponse = {
    newResponse: null,
};

function* add({ payload: { entrydate, amount, itemid, costnumber, quantity, costdetdata } }: EmpData): SagaIterator {
    try {
        const response = yield call(addBOM_api, { entrydate, amount, itemid, costnumber, quantity, costdetdata });
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(processApiResponseSuccess(ProcessActionTypes.ADD_BOM, item));
    } catch (error: any) {
        yield put(processApiResponseError(ProcessActionTypes.ADD_BOM, error));
    }
}
function* add_PRD({
    payload: { entrydate, description, amount, peamount, productiondata },
}: PRD_Data): SagaIterator {
    try {
        const response = yield call(addPRD_api, { entrydate, description, amount, peamount, productiondata });
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(processApiResponseSuccess(ProcessActionTypes.ADD_PRD, item));
    } catch (error: any) {
        yield put(processApiResponseError(ProcessActionTypes.ADD_PRD, error));
    }
}
function* edit_PRD({
    payload: { id,entrydate, description, amount, peamount, productiondata },
}: PRD_Data): SagaIterator {
    try {
        const response = yield call(editPRD_api, {id,entrydate, description, amount, peamount, productiondata });
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(processApiResponseSuccess(ProcessActionTypes.EDIT_PRD, item));
    } catch (error: any) {
        yield put(processApiResponseError(ProcessActionTypes.EDIT_PRD, error));
    }
}
function* manageProcess({ payload: { name } }: mangeProcess_data): SagaIterator {
    try {
        const response = yield call(manageProcess_Api, { name });
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(processApiResponseSuccess(ProcessActionTypes.MANAGE_PROCESS, item));
    } catch (error: any) {
        yield put(processApiResponseError(ProcessActionTypes.MANAGE_PROCESS, error));
    }
}
function* manufacProcess({ payload: { proecessdata } }: manufac_data): SagaIterator {
    try {
        const response = yield call(manufacProcess_Api, { proecessdata });
        setResponse['newResponse'] = response;
        const item = response.data;
        yield put(processApiResponseSuccess(ProcessActionTypes.MANUFAC_PROCESS, item));
    } catch (error: any) {
        yield put(processApiResponseError(ProcessActionTypes.MANUFAC_PROCESS, error));
    }
}
export function* watchAdd(): any {
    yield takeEvery(ProcessActionTypes.ADD_BOM, add);
}
export function* watchManufacProcess(): any {
    yield takeEvery(ProcessActionTypes.MANUFAC_PROCESS, manufacProcess);
}
export function* watchManageProcess(): any {
    yield takeEvery(ProcessActionTypes.MANAGE_PROCESS, manageProcess);
}
export function* watchAdd_PRD(): any {
    yield takeEvery(ProcessActionTypes.ADD_PRD, add_PRD);
}
export function* watchEdit_PRD(): any {
    yield takeEvery(ProcessActionTypes.EDIT_PRD, edit_PRD);
}

function* employeeSaga() {
    yield all([fork(watchAdd)]);
    yield all([fork(watchEdit_PRD)]);
    yield all([fork(watchManageProcess)]);
    yield all([fork(watchAdd_PRD)]);
    yield all([fork(watchManufacProcess)]);
}

export default employeeSaga;
