import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import {
    shortcutbarRight_API,
    add_userManagement as userApi,
    add_financialYear,
    roles_API,
    updateUserApi,
    dashboardRight_API,
    empRight_API,
    manageLogin_API,
    cashcounterRight_API,
} from '../../helpers';

import { adminResponseSuccess, adminResponseError } from './actions';

// constants
import { AdminActionTypes } from './constants';
type users_Type = {
    payload: {
        purchaseprice_rights: string;
        salesprice_rights: string;
        username: string;
        name: string;
        email: string;
        password: string;
        roleid: string;
        branchid: string;
        financialYear: string;
        salescategory: string;
        salesman: string;
        itembrand: string;
        category: string;
        location: string;
        customerGroup: string;
        supplierGroup: string;
        VenGroupCheck: string;
        accounts_rights: string;
        groupaccounts_rights: string;
        salesman_disabled: boolean;
        location_disabled: boolean;
        accounts_disabled: boolean;
        otherdate_rights: boolean;
        userwiseentries: boolean;
    };
    type: string;
};
type upd_users_Type = {
    payload: {
        id: string;
        purchaseprice_rights: string;
        salesprice_rights: string;
        username: string;
        name: string;
        email: string;
        password: string;
        roleid: string;
        branchid: string;
        financialYear: string;
        salescategory: string;
        salesman: string;
        itembrand: string;
        category: string;
        location: string;
        customerGroup: string;
        supplierGroup: string;
        VenGroupCheck: string;
        accounts_rights: string;
        groupaccounts_rights: string;
        salesman_disabled: boolean;
        location_disabled: boolean;
        accounts_disabled: boolean;
        otherdate_rights: boolean;
        userwiseentries: boolean;
    };
    type: string;
};
type finalYear_type = {
    payload: { title: string; description: string; startdate: string; enddate: string; yearstatus: string };
    type: string;
};
type roles_Type = {
    payload: {
        description: string;
        rightsdata: [{ menuid: string; isallow: string; isedit: string; isinactive: string; isdelete: string }];
    };
    type: string;
};
type dashboard_right_Type = {
    payload: { userid: string; rightsdata: object; mobilerightsdata: object; icontype: object };
    type: string;
};
type emp_right_Type = {
    payload: { userid: string; employeeid: string };
    type: string;
};
type cashcounter_right_Type = {
    payload: { userid: string; rightsdata: object };
    type: string;
};
function* add_FinalYear({
    payload: { title, description, startdate, enddate, yearstatus },
}: finalYear_type): SagaIterator {
    try {
        const response = yield call(add_financialYear, { title, description, startdate, enddate, yearstatus });
        console.log(response);
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.FINANCIAL_YEAR, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.FINANCIAL_YEAR, error));
    }
}
function* add_Users({
    payload: {
        purchaseprice_rights,
        salesprice_rights,
        username,
        name,
        email,
        password,
        roleid,
        branchid,
        financialYear,
        salescategory,
        salesman,
        itembrand,
        category,
        location,
        customerGroup,
        supplierGroup,
        accounts_rights,
        groupaccounts_rights,
        salesman_disabled,
        location_disabled,
        accounts_disabled,
        otherdate_rights,
        userwiseentries,
    },
}: users_Type): SagaIterator {
    try {
        const response = yield call(userApi, {
            purchaseprice_rights,
            salesprice_rights,
            username,
            name,
            email,
            password,
            roleid,
            branchid,
            financialYear,
            salescategory,
            salesman,
            itembrand,
            category,
            location,
            customerGroup,
            supplierGroup,
            accounts_rights,
            groupaccounts_rights,
            salesman_disabled,
            location_disabled,
            accounts_disabled,
            otherdate_rights,
            userwiseentries,
        });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.USER_MANAGEMENT, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.USER_MANAGEMENT, error));
    }
}
function* upd_Users({
    payload: {
        id,
        purchaseprice_rights,
        salesprice_rights,
        username,
        name,
        email,
        password,
        roleid,
        branchid,
        financialYear,
        salescategory,
        salesman,
        itembrand,
        category,
        location,
        customerGroup,
        supplierGroup,
        accounts_rights,
        groupaccounts_rights,
        salesman_disabled,
        location_disabled,
        accounts_disabled,
        otherdate_rights,
        userwiseentries,
    },
}: upd_users_Type): SagaIterator {
    try {
        const response = yield call(updateUserApi, {
            id,
            purchaseprice_rights,
            salesprice_rights,
            username,
            name,
            email,
            password,
            roleid,
            branchid,
            financialYear,
            salescategory,
            salesman,
            itembrand,
            category,
            location,
            customerGroup,
            supplierGroup,
            accounts_rights,
            groupaccounts_rights,
            salesman_disabled,
            location_disabled,
            accounts_disabled,
            otherdate_rights,
            userwiseentries,
        });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.USER_UPDATE, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.USER_UPDATE, error));
    }
}
function* add_Role({ payload: { description, rightsdata } }: roles_Type): SagaIterator {
    try {
        const response = yield call(roles_API, { description, rightsdata });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.ROLE, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.ROLE, error));
    }
}
function* add_dashbaordRights({ payload: { userid, rightsdata } }: dashboard_right_Type): SagaIterator {
    try {
        const response = yield call(dashboardRight_API, { userid, rightsdata });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.DASHBOARD_RIGHTS, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.DASHBOARD_RIGHTS, error));
    }
}
function* add_shortcutbarRights({
    payload: { userid, rightsdata, mobilerightsdata, icontype },
}: dashboard_right_Type): SagaIterator {
    try {
        const response = yield call(shortcutbarRight_API, { userid, rightsdata, mobilerightsdata, icontype });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.SHORTCUT_RIGHTS, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.SHORTCUT_RIGHTS, error));
    }
}
function* add_EmpRight({ payload: { userid, employeeid } }: emp_right_Type): SagaIterator {
    try {
        const response = yield call(empRight_API, { userid, employeeid });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.EMP_RIGHTS, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.EMP_RIGHTS, error));
    }
}
function* add_manageLogin({
    payload: { themename, file },
}: {
    payload: { themename: string; file: string };
    type: string;
}): SagaIterator {
    try {
        const response = yield call(manageLogin_API, { themename, file });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.MANAGE_LOGIN, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.MANAGE_LOGIN, error));
    }
}
function* add_cashcounterRights({ payload: { userid, rightsdata } }: cashcounter_right_Type): SagaIterator {
    try {
        const response = yield call(cashcounterRight_API, { userid, rightsdata });
        const item = response.data;
        yield put(adminResponseSuccess(AdminActionTypes.CASHCOUNTER_RIGHTS, item));
    } catch (error) {
        yield put(adminResponseError(AdminActionTypes.CASHCOUNTER_RIGHTS, error));
    }
}
export function* watchAdd(): any {
    yield takeEvery(AdminActionTypes.EMP_RIGHTS, add_EmpRight);
    yield takeEvery(AdminActionTypes.FINANCIAL_YEAR, add_FinalYear);
    yield takeEvery(AdminActionTypes.USER_MANAGEMENT, add_Users);
    yield takeEvery(AdminActionTypes.SHORTCUT_RIGHTS, add_shortcutbarRights);
    yield takeEvery(AdminActionTypes.ROLE, add_Role);
    yield takeEvery(AdminActionTypes.MANAGE_LOGIN, add_manageLogin);
    yield takeEvery(AdminActionTypes.USER_UPDATE, upd_Users);
    yield takeEvery(AdminActionTypes.DASHBOARD_RIGHTS, add_dashbaordRights);
    yield takeEvery(AdminActionTypes.CASHCOUNTER_RIGHTS, add_cashcounterRights);
}
function* adminSaga() {
    yield all([fork(watchAdd)]);
}
export default adminSaga;