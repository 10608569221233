import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import itmmngtSaga from './itmmngt/saga';
import customerSaga from './customer/saga';
import supplierSaga from './supplier/saga';
import layoutSaga from './layout/saga';
import salesSaga from  './sales/saga';
import adminSaga from  './admin/saga';
import appSaga from  './app/saga';
import employeeSaga from  './employee/saga';
import ledgerSaga from  './Ledger/saga';
import purchaseSaga from  './purchase/saga'; 
import BankSaga from './bank/saga';
import ServicesSaga from './Services/saga'
import Processing from './Processing/saga'

export default function* rootSaga() {
    yield all([Processing(),authSaga(),BankSaga(),employeeSaga(), itmmngtSaga(), layoutSaga(), customerSaga(),salesSaga(), supplierSaga(),adminSaga(),appSaga(),ledgerSaga(),purchaseSaga(),ServicesSaga()]);
}
