import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
    addCheque_Payments,
    upd_Refund_API,
    add_Refund_API,add_Cancel_API,
    add_chequetranferReturn_API,
    addCheque_Receipts,
    updCheque_Tranfer_api,
    upd_ChequePayReturn_API,
    managebank_API,
    add_chequetranfer_API,
    upd_enCash_API,
    updCheque_Payments,
    add_BankAcc_API,
    updCheque_Receipts,
    add_ChequePayReturn_API,
    upd_chequetranferReturn_API,
    add_enCash_API,
} from '../../helpers';
import { BankResponseSuccess, BankResponseError } from './actions';
// constants
import { BankActionTypes } from './constants';
type cashReceiptData = {
    payload: { id: string; entrydate: string; description: string; totalamount: string; voucherdetdata: [] };
    type: string;
};
type CancelData = {
    payload: { bankid: string; booknumber: string; chequenumber: string; description: string };
    type: string;
};
type BankAccData = {
    payload: {
        accountid: string;
        bankid: string;
        name: string;
        acctnumber: string;
        iban: string;
        branch: string;
        branchcode: string;
        address: string;
        detaildata: object;
    };
    type: string;
};
type bankData = {
    payload: { name: string };
    type: string;
};

function* add_chequeRec({
    payload: { entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(addCheque_Receipts, { entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.Add_CheckReceipt, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.Add_CheckReceipt, error));
    }
}
function* add_Bank_Acc({
    payload: { accountid, bankid, name, acctnumber, iban, branch, branchcode, address, detaildata },
}: BankAccData): SagaIterator {
    try {
        const response = yield call(add_BankAcc_API, {
            accountid,
            bankid,
            name,
            acctnumber,
            iban,
            branchcode,
            branch,
            address,
            detaildata,
        });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.ADD_BANK_ACC, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.ADD_BANK_ACC, error));
    }
}
function* add_enCash({
    payload: { entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(add_enCash_API, { entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.Add_ENCASH, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.Add_ENCASH, error));
    }
}
function* add_Refund({
    payload: { entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(add_Refund_API, { entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.Add_REFUND, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.Add_REFUND, error));
    }
}
function* add_ChequePayReturn({
    payload: { entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(add_ChequePayReturn_API, { entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.ADD_CHEQUEPAYRETURN, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.ADD_CHEQUEPAYRETURN, error));
    }
}
function* add_Cancel({ payload: { bankid, booknumber, chequenumber, description } }: CancelData): SagaIterator {
    try {
        const response = yield call(add_Cancel_API, { bankid, booknumber, chequenumber, description });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.Add_CANCEL, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.Add_CANCEL, error));
    }
}
function* add_chequetranfer({
    payload: { entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(add_chequetranfer_API, { entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.ADD_CHEQUETRANSFER, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.ADD_CHEQUETRANSFER, error));
    }
}
function* add_chequetranferReturn({
    payload: { entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(add_chequetranferReturn_API, {
            entrydate,
            description,
            totalamount,
            voucherdetdata,
        });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.ADD_CHEQUETRANSFERRETURN, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.ADD_CHEQUETRANSFERRETURN, error));
    }
}

function* upd_enCash({
    payload: { id, entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(upd_enCash_API, { id, entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_ENCASH, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_ENCASH, error));
    }
}
function* upd_Refunds({
    payload: { id, entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(upd_Refund_API, { id, entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_RFUND, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_RFUND, error));
    }
}
function* upd_ChequePayReturn({
    payload: { id, entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(upd_ChequePayReturn_API, { id, entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_CHEQUEPAYRETURN, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_CHEQUEPAYRETURN, error));
    }
}

function* upd_chequetranferReturn({
    payload: { id, entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(upd_chequetranferReturn_API, {
            id,
            entrydate,
            description,
            totalamount,
            voucherdetdata,
        });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_CHQUETRANFERRETURN, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_CHQUETRANFERRETURN, error));
    }
}

function* upd_chequeRec({
    payload: { id, entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(updCheque_Receipts, { id, entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_CheckReceipt, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_CheckReceipt, error));
    }
}
function* upd_chequeTransfer({
    payload: { id, entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(updCheque_Tranfer_api, { id, entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_CHQUETRANFER, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_CHQUETRANFER, error));
    }
}
function* add_chequePayment({
    payload: { entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(addCheque_Payments, { entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.Add_CheckPayment, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.Add_CheckPayment, error));
    }
}
function* upd_chequePayment({
    payload: { id, entrydate, description, totalamount, voucherdetdata },
}: cashReceiptData): SagaIterator {
    try {
        const response = yield call(updCheque_Payments, { id, entrydate, description, totalamount, voucherdetdata });
        const cheque_Voucher = response.data;
        yield put(BankResponseSuccess(BankActionTypes.UPD_CheckPayment, cheque_Voucher));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.UPD_CheckPayment, error));
    }
}

function* carrier_Add({ payload: { name } }: bankData): SagaIterator {
    try {
        const response = yield call(managebank_API, { name });
        const item = response.data;
        yield put(BankResponseSuccess(BankActionTypes.ADD_BANK, item));
    } catch (error: any) {
        yield put(BankResponseError(BankActionTypes.ADD_BANK, error));
    }
}

export function* watchAddRec(): any {
    yield takeEvery(BankActionTypes.Add_CheckReceipt, add_chequeRec);
    yield takeEvery(BankActionTypes.UPD_CheckReceipt, upd_chequeRec);
    yield takeEvery(BankActionTypes.Add_CheckPayment, add_chequePayment);
    yield takeEvery(BankActionTypes.UPD_CheckPayment, upd_chequePayment);
    yield takeEvery(BankActionTypes.ADD_BANK, carrier_Add);
    yield takeEvery(BankActionTypes.UPD_ENCASH, upd_enCash);
    yield takeEvery(BankActionTypes.ADD_BANK_ACC, add_Bank_Acc);
    yield takeEvery(BankActionTypes.UPD_CHQUETRANFER, upd_chequeTransfer);
    yield takeEvery(BankActionTypes.ADD_CHEQUETRANSFER, add_chequetranfer);
    yield takeEvery(BankActionTypes.UPD_CHQUETRANFERRETURN, upd_chequetranferReturn);
    yield takeEvery(BankActionTypes.Add_REFUND, add_Refund);
    yield takeEvery(BankActionTypes.ADD_CHEQUETRANSFERRETURN, add_chequetranferReturn);
    yield takeEvery(BankActionTypes.UPD_RFUND, upd_Refunds);
    yield takeEvery(BankActionTypes.UPD_CHEQUEPAYRETURN, upd_ChequePayReturn);
    yield takeEvery(BankActionTypes.Add_CANCEL, add_Cancel);
    yield takeEvery(BankActionTypes.ADD_CHEQUEPAYRETURN, add_ChequePayReturn);
    yield takeEvery(BankActionTypes.Add_ENCASH, add_enCash);
}

function* BankSaga() {
    yield all([fork(watchAddRec)]);
}

export default BankSaga;
