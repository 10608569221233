import React, { Suspense, useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';
// lazy load all the views
// auth
const componentMapping = {
    Login: React.lazy(() => import('../pages/auth/Login')),
    Register: React.lazy(() => import('../pages/auth/Register')),
    Confirm: React.lazy(() => import('../pages/auth/Confirm')),
    ForgetPassword: React.lazy(() => import('../pages/auth/ForgetPassword')),
    LockScreen: React.lazy(() => import('../pages/auth/LockScreen')),

    // dashboards
    DashBoard1: React.lazy(() => import('../pages/dashboards/DashBoard1/')),
    PurchaseDashboard: React.lazy(() => import('../pages/dashboards/PurchaseDashboard/')),
    SalesDashboard: React.lazy(() => import('../pages/dashboards/SalesDashbaord/')),
    ChartAnalysis: React.lazy(() => import('../pages/dashboards/PurchaseDashboard/ChartAnalysis')),
    // Admin
    FinancialYear: React.lazy(() => import('../pages/Admin/FinancialYear')),
    UsersList: React.lazy(() => import('../pages/Admin/ManageUsers/UsersList')),
    AddUser: React.lazy(() => import('../pages/Admin/ManageUsers/AddUser')),
    EditUser: React.lazy(() => import('../pages/Admin/ManageUsers/EditUser')),
    MenuRights: React.lazy(() => import('../pages/Admin/ManageUsers/MenuRights')),
    ManageRole: React.lazy(() => import('../pages/Admin/ManageRole')),
    AddRole: React.lazy(() => import('../pages/Admin/AddRole')),
    EditRole: React.lazy(() => import('../pages/Admin/EditRole')),
    ManageLoginPage: React.lazy(() => import('../pages/Admin/ManageLoginPage')),
    ManagePDFFormat: React.lazy(() => import('../pages/Admin/ManagePDFFormat')),
    EmployeeRights: React.lazy(() => import('../pages/Admin/EmployeeRights')),
    ManageDashboardRight: React.lazy(() => import('../pages/Admin/ManageDashboardRight')),
    ShortcutBarRights: React.lazy(() => import('../pages/Admin/ShortcutBarRights')),
    // ManageShortcut : React.lazy(() => import('../pages/Admin/ManageShortcut')),
    UserLog: React.lazy(() => import('../pages/Admin/Reports/UserLog')),

    // apps
    CalendarApp: React.lazy(() => import('../pages/apps/Calendar')),
    ChatApp: React.lazy(() => import('../pages/apps/Chat')),
    Inbox: React.lazy(() => import('../pages/apps/Email/Inbox')),
    Kanban: React.lazy(() => import('../pages/apps/Tasks/Board')),
    AdminDetailBoard: React.lazy(() => import('../pages/apps/Tasks/Board/AdminDetailBoard')),
    TaskList: React.lazy(() => import('../pages/apps/Tasks/TaskList')),
    TaskDetail: React.lazy(() => import('../pages/apps/Tasks/Detail')),
    AdminDetails: React.lazy(() => import('../pages/apps/Tasks/Detail/AdminDetails')),
    Projects: React.lazy(() => import('../pages/apps/Projects')),
    ContactList: React.lazy(() => import('../pages/apps/Contacts/List')),
    Profile: React.lazy(() => import('../pages/apps/Contacts/Profile')),
    ManagePosition: React.lazy(() => import('../pages/apps/Setups/ManagePosition')),
    ManagePriority: React.lazy(() => import('../pages/apps/Setups/ManagePriority')),

    // extra pages
    Starter: React.lazy(() => import('../pages/other/Starter')),
    Pricing: React.lazy(() => import('../pages/other/Pricing')),
    Timeline: React.lazy(() => import('../pages/other/Timeline')),
    Invoice: React.lazy(() => import('../pages/other/Invoice')),
    FAQ: React.lazy(() => import('../pages/other/FAQ')),
    Gallery: React.lazy(() => import('../pages/other/Gallery')),
    Error404: React.lazy(() => import('../pages/other/Error404')),
    Error500: React.lazy(() => import('../pages/other/Error500')),
    Maintenance: React.lazy(() => import('../pages/other/Maintenance')),
    ComingSoon: React.lazy(() => import('../pages/other/ComingSoon')),

    // base ui
    Buttons: React.lazy(() => import('../pages/uikit/Buttons')),
    Cards: React.lazy(() => import('../pages/uikit/Cards')),
    Avatars: React.lazy(() => import('../pages/uikit/Avatars')),
    TabsAccordions: React.lazy(() => import('../pages/uikit/TabsAccordions')),
    Notifications: React.lazy(() => import('../pages/uikit/Notifications')),
    Modals: React.lazy(() => import('../pages/uikit/Modals')),
    Progress: React.lazy(() => import('../pages/uikit/Progress')),
    Offcanvases: React.lazy(() => import('../pages/uikit/Offcanvases')),
    Placeholders: React.lazy(() => import('../pages/uikit/Placeholders')),
    Spinners: React.lazy(() => import('../pages/uikit/Spinners')),
    Images: React.lazy(() => import('../pages/uikit/Images')),
    Carousel: React.lazy(() => import('../pages/uikit/Carousel')),
    EmbedVedio: React.lazy(() => import('../pages/uikit/EmbedVideo')),
    Dropdowns: React.lazy(() => import('../pages/uikit/Dropdowns')),
    PopoversAndTooltips: React.lazy(() => import('../pages/uikit/PopoversAndTooltips')),
    GeneralUI: React.lazy(() => import('../pages/uikit/GeneralUI')),
    Typography: React.lazy(() => import('../pages/uikit/Typography')),
    Grid: React.lazy(() => import('../pages/uikit/Grid')),

    // widgets
    Widgets: React.lazy(() => import('../pages/uikit/Widgets')),

    // extended ui
    RangeSliders: React.lazy(() => import('../pages/uikit/RangeSlider')),
    NestableList: React.lazy(() => import('../pages/uikit/NestableList')),
    SweetAlerts: React.lazy(() => import('../pages/uikit/SweetAlerts')),
    Tourpage: React.lazy(() => import('../pages/uikit/TourPage')),
    TreeViewExample: React.lazy(() => import('../pages/uikit/TreeView')),
    // icons
    FeatherIcons: React.lazy(() => import('../pages/icons/FeatherIcons')),
    MDIIcons: React.lazy(() => import('../pages/icons/MDIIcons')),
    Dripicons: React.lazy(() => import('../pages/icons/DripiIcons')),
    FontAwesomeIcons: React.lazy(() => import('../pages/icons/FontAwesomeIcons')),
    ThemifyIcons: React.lazy(() => import('../pages/icons/ThemifyIcons')),
    // forms
    GeneralElements: React.lazy(() => import('../pages/forms/Basic')),
    FormAdvanced: React.lazy(() => import('../pages/forms/Advanced')),
    Validation: React.lazy(() => import('../pages/forms/Validation')),
    FormWizard: React.lazy(() => import('../pages/forms/Wizard')),
    FileUpload: React.lazy(() => import('../pages/forms/FileUpload')),
    Editors: React.lazy(() => import('../pages/forms/Editors')),
    Grid1: React.lazy(() => import('../pages/forms/Grid')),
    // tables
    BasicTable: React.lazy(() => import('../pages/tables/BasicTable')),
    AdvancedTable: React.lazy(() => import('../pages/tables/AdvancedTable')),
    // reports
    CustomerBalanceRep: React.lazy(() => import('../pages/sales-reports/CustomerBalanceRep')),
    ReceiptReport: React.lazy(() => import('../pages/sales-reports/ReceiptReport')),
    ReceivablesAging: React.lazy(() => import('../pages/sales-reports/ReceivablesAging')),
    CustomerDebitNoteDetails: React.lazy(() => import('../pages/sales-reports/CustomerDebitNoteDetails')),
    CustomerDebitNoteSummary: React.lazy(() => import('../pages/sales-reports/CustomerDebitNoteSummary')),
    CustomerCreditNoteSummary: React.lazy(() => import('../pages/sales-reports/CustomerCreditNoteSummary')),
    CustomerCreditNoteDetails: React.lazy(() => import('../pages/sales-reports/CustomerCreditNoteDetails')),
    // charts
    ApexChart: React.lazy(() => import('../pages/chart/ApexChart')),
    ChartJs: React.lazy(() => import('../pages/chart/ChartJs')),
    // maps
    GoogleMaps: React.lazy(() => import('../pages/maps/GoogleMaps')),
    VectorMaps: React.lazy(() => import('../pages/maps/VectorMaps')),
    // Processing
    BillofMaterial: React.lazy(() => import('../pages/Manufacturing/BillofMaterial')),
    ProcessRates: React.lazy(() => import('../pages/Manufacturing/ProcessRates')),
    BillofMaterialList: React.lazy(() => import('../pages/Manufacturing/BillofMaterialList')),
    Production: React.lazy(() => import('../pages/Manufacturing/Production')),
    ProductionList: React.lazy(() => import('../pages/Manufacturing/ProductionList')),
    EditProduction: React.lazy(() => import('../pages/Manufacturing/EditProduction')),
    ManageProcess: React.lazy(() => import('../pages/Manufacturing/ManageProcess')),
    // items
    InactiveItems: React.lazy(() => import('../pages/itmmngt/InactiveItems')),
    StockAdjustmentSummary: React.lazy(() => import('../pages/itmmngt/StockAdjustmentSummary')),
    StockAdjustmentDetails: React.lazy(() => import('../pages/itmmngt/StockAdjustmentDetails')),
    ItemList: React.lazy(() => import('../pages/itmmngt/ItemList')),
    ItemExcelImport: React.lazy(() => import('../pages/itmmngt/ItemExcelImport')),
    AddItem: React.lazy(() => import('../pages/itmmngt/AddItem')),
    EditItem: React.lazy(() => import('../pages/itmmngt/EditItem')),
    ItemBrand: React.lazy(() => import('../pages/itmmngt/ItemBrand')),
    ItemCategory: React.lazy(() => import('../pages/itmmngt/ItemCategory')),
    MultipleItemsAdd: React.lazy(() => import('../pages/itmmngt/MultipleItemsAdd')),
    MultipleItemUpdate: React.lazy(() => import('../pages/itmmngt/MultipleItemUpdate')),
    ItemSubCategory: React.lazy(() => import('../pages/itmmngt/ItemSubCategory')),
    ManageItemType: React.lazy(() => import('../pages/itmmngt/ManageItemType')),
    ManageItemPrice: React.lazy(() => import('../pages/itmmngt/ManageItemPrice')),
    ManageItemCode: React.lazy(() => import('../pages/itmmngt/ManageItemCode')),
    ManageStockLocations: React.lazy(() => import('../pages/itmmngt/ManageStockLocations')),
    InventoryPrintVoucherList: React.lazy(() => import('../pages/itmmngt/InventoryPrintVoucherList')),
    ManageUnit: React.lazy(() => import('../pages/itmmngt/ManageUnit')),
    ItemLedger: React.lazy(() => import('../pages/inventory-reports/ItemLedger')),
    stockAnalysis: React.lazy(() => import('../pages/inventory-reports/stockAnalysis')),
    StockReport: React.lazy(() => import('../pages/inventory-reports/StockReport')),
    BankAccounts: React.lazy(() => import('../pages/bank/setup/BankAccounts')),
    StockValueReport: React.lazy(() => import('../pages/inventory-reports/StockValueReport')),
    StockoutDetails: React.lazy(() => import('../pages/itmmngt/StockoutDetails')),
    StockoutSummary: React.lazy(() => import('../pages/itmmngt/StockoutSummary')),
    TransferSummary: React.lazy(() => import('../pages/itmmngt/TransferSummary')),
    TransferDetails: React.lazy(() => import('../pages/itmmngt/TransferDetails')),
    StockinSummary: React.lazy(() => import('../pages/itmmngt/StockinSummary')),
    StockinDetails: React.lazy(() => import('../pages/itmmngt/StockinDetails')),

    // Opening Stock
    OpeningStockList: React.lazy(() => import('../pages/itmmngt/OpeningStockList')),
    EditOpeningStock: React.lazy(() => import('../pages/itmmngt/EditOpeningStock')),
    AddOpeningStock: React.lazy(() => import('../pages/itmmngt/AddOpeningStock')),

    // Stock Adjustment
    AddStockAdjustment: React.lazy(() => import('../pages/itmmngt/AddStockAdjustment')),
    StockAdjustmentList: React.lazy(() => import('../pages/itmmngt/StockAdjustmentList')),
    EditStockAdjustment: React.lazy(() => import('../pages/itmmngt/EditStockAdjustment')),

    // Stock Transfer
    StockTransferList: React.lazy(() => import('../pages/itmmngt/StockTransferList')),
    EditStockTransfer: React.lazy(() => import('../pages/itmmngt/EditStockTransfer')),
    AddStockTransfer: React.lazy(() => import('../pages/itmmngt/AddStockTransfer')),

    // Stock In
    AddStockIn: React.lazy(() => import('../pages/itmmngt/AddStockIn')),
    StockInList: React.lazy(() => import('../pages/itmmngt/StockInList')),
    EditStockIn: React.lazy(() => import('../pages/itmmngt/EditStockIn')),

    // Stock Out
    AddStockOut: React.lazy(() => import('../pages/itmmngt/AddStockOut')),
    StockOutList: React.lazy(() => import('../pages/itmmngt/StockOutList')),
    EditStockOut: React.lazy(() => import('../pages/itmmngt/EditStockOut')),

    // customers
    AddCustomer: React.lazy(() => import('../pages/Customer/AddCustomer')),
    CustomerList: React.lazy(() => import('../pages/Customer/CustomerList')),
    CustomerLedger: React.lazy(() => import('../pages/Customer/CustomerLedger')),
    EditCustomer: React.lazy(() => import('../pages/Customer/EditCustomer')),
    CustomerGroup: React.lazy(() => import('../pages/Customer/CustomerGroup')),
    SetInvoiceID: React.lazy(() => import('../pages/Customer/SetInvoiceID')),
    SetSalesPerson: React.lazy(() => import('../pages/Customer/SetSalesPerson')),
    ManageSaleCat: React.lazy(() => import('../pages/Customer/ManageSaleCat')),
    CustomerAllVoucherList: React.lazy(() => import('../pages/Customer/CustomerAllVoucherList')),
    AddCustomerOpeningBalance: React.lazy(() => import('../pages/Customer/AddCustomerOpeningBalance')),
    CustomerBalance_list: React.lazy(() => import('../pages/Customer/CustomerBalance_list')),
    MultipleCustomerAdd: React.lazy(() => import('../pages/Customer/MultipleCustomerAdd')),
    MultipleCustomerUpdate: React.lazy(() => import('../pages/Customer/MultipleCustomerUpdate')),
    ExcelImport: React.lazy(() => import('../pages/Customer/ExcelImport')),
    InactiveCustomer: React.lazy(() => import('../pages/Customer/InactiveCustomer')),
    Edit_CustomerBalance: React.lazy(() => import('../pages/Customer/Edit_CustomerBalance')),
    Manage_Carrier: React.lazy(() => import('../pages/Customer/Manage_Carrier')),
    Manage_CashCounter: React.lazy(() => import('../pages/Customer/Manage_CashCounter')),
    CounterSale: React.lazy(() => import('../pages/Customer/CounterSale')),
    PostVoucherList: React.lazy(() => import('../pages/Customer/PostVoucherList')),

    // Invoice
    AddSaleInvoice: React.lazy(() => import('../pages/invoices/addSaleInv')),
    SaleInvoiceList: React.lazy(() => import('../pages/invoices/SaleInv_List')),
    EditSaleInv: React.lazy(() => import('../pages/invoices/EditSaleInv')),
    InvoiceReturnList: React.lazy(() => import('../pages/invoices/InvoiceReturnList')),
    AddInvoiceReturn: React.lazy(() => import('../pages/invoices/AddInvoiceReturn')),
    EditInvReturn: React.lazy(() => import('../pages/invoices/EditInvReturn')),

    // Services
    AddServiceOrder: React.lazy(() => import('../pages/Services/AddServiceOrder')),
    PrintServicesVoucher: React.lazy(() => import('../pages/Services/PrintServicesVoucher')),
    IncomeAndExpense: React.lazy(() => import('../pages/Services/IncomeAndExpense')),
    ServicesWiseSales: React.lazy(() => import('../pages/Services/ServicesWiseSales')),
    OrderStages: React.lazy(() => import('../pages/Services/OrderStages')),
    OrderProgress: React.lazy(() => import('../pages/Services/OrderProgress')),
    ToolsExpenseDetail: React.lazy(() => import('../pages/Services/ToolsExpenseDetail')),
    ToolsExpenseSummary: React.lazy(() => import('../pages/Services/ToolsExpenseSummary')),
    EditToolsExpense: React.lazy(() => import('../pages/Services/EditToolsExpense')),
    ToolsExpenseList: React.lazy(() => import('../pages/Services/ToolsExpenseList')),
    AddToolsExpense: React.lazy(() => import('../pages/Services/AddToolsExpense')),
    EditServicesOrder: React.lazy(() => import('../pages/Services/EditServicesOrder')),
    ServicesOrdersList: React.lazy(() => import('../pages/Services/ServicesOrdersList')),
    ServicesInvoiceSummary: React.lazy(() => import('../pages/Services/services-analysis/ServicesInvoiceSummary')),
    ServicesInvoiceDetails: React.lazy(() => import('../pages/Services/services-analysis/ServicesInvoiceDetails')),

    PendingServicesOrders: React.lazy(() => import('../pages/Services/PendingServicesOrders')),
    ClosePendingServicesOrders: React.lazy(() => import('../pages/Services/ClosePendingServicesOrders')),
    ClosedServicesOrders: React.lazy(() => import('../pages/Services/ClosedServicesOrders')),
    ServicesOrderSummary: React.lazy(() => import('../pages/Services/ServicesOrderSummary')),
    ServicesOrderDetails: React.lazy(() => import('../pages/Services/ServicesOrderDetails')),

    AddServicesInvoice: React.lazy(() => import('../pages/Services/AddServicesInvoice')),
    ServicesInvoiceList: React.lazy(() => import('../pages/Services/ServicesInvoiceList')),
    EditServicesInvoice: React.lazy(() => import('../pages/Services/EditServicesInvoice')),
    ManageQuerySource: React.lazy(() => import('../pages/Services/ManageQuerySource')),
    ManageToolsMachine: React.lazy(() => import('../pages/Services/ManageToolsMachine')),
    ManageServicesPerson: React.lazy(() => import('../pages/Services/ManageServicesPerson')),

    /// Sales Order
    AddSalesOrder: React.lazy(() => import('../pages/invoices/AddSalesOrder')),
    SalesOrderList: React.lazy(() => import('../pages/invoices/SalesOrderList')),
    EditSalesOrder: React.lazy(() => import('../pages/invoices/EditSalesOrder')),
    PendingSalesOrder: React.lazy(() => import('../pages/invoices/PendingSalesOrder')),
    ClosePendingSalesOrders: React.lazy(() => import('../pages/invoices/ClosePendingSalesOrders')),
    ClosedSalesOrders: React.lazy(() => import('../pages/invoices/ClosedSalesOrders')),
    SalesOrderSummary: React.lazy(() => import('../pages/invoices/SalesOrderSummary')),
    DeliverynoteSummary: React.lazy(() => import('../pages/invoices/DeliverynoteSummary')),
    SalesReturnSummary: React.lazy(() => import('../pages/invoices/SalesReturnSummary')),

    /// Delivery Note
    AddDeliveryNote: React.lazy(() => import('../pages/invoices/AddDeliveryNote')),
    EditDeliveryNote: React.lazy(() => import('../pages/invoices/EditDeliveryNote')),
    DeliveryNoteList: React.lazy(() => import('../pages/invoices/DeliveryNoteList')),
    PendingDeliveryNotes: React.lazy(() => import('../pages/invoices/PendingDeliveryNotes')),

    // analytics

    ItemWiseSales: React.lazy(() => import('../pages/sales-analysis/ItemWiseSales')),
    ReportList: React.lazy(() => import('../pages/sales-analysis/ReportList')),
    CustomerWiseSales: React.lazy(() => import('../pages/sales-analysis/CustomerWiseSales')),

    InvoiceSummary: React.lazy(() => import('../pages/sales-analysis/InvoiceSummary')),
    Analytsis_Table: React.lazy(() => import('../pages/sales-analysis/Analytsis_Table')),
    SalesandRecovery: React.lazy(() => import('../pages/sales-analysis/SalesandRecovery')),
    InvoicesDetail: React.lazy(() => import('../pages/sales-analysis/InvoicesDetail')),
    SalesOrderDetails: React.lazy(() => import('../pages/sales-analysis/SalesOrderDetails')),
    SalesReturnDetails: React.lazy(() => import('../pages/sales-analysis/SalesReturnDetails')),
    Deliverynotesdetails: React.lazy(() => import('../pages/sales-analysis/Deliverynotesdetails')),

    // employee

    AddEmployee: React.lazy(() => import('../pages/employee/addEmployee')),
    EmployeeList: React.lazy(() => import('../pages/employee/EmployeeList')),
    EditEmployee: React.lazy(() => import('../pages/employee/editEmployee')),
    EmployeeOpeningBalance: React.lazy(() => import('../pages/employee/EmployeeOpeningBalance')),
    Manage_Bonustype: React.lazy(() => import('../pages/employee/Manage_Bonustype')),
    Manage_Jobtype: React.lazy(() => import('../pages/employee/Manage_Jobtype')),
    Manage_Qualification: React.lazy(() => import('../pages/employee/Manage_Qualification')),
    Manage_Groups: React.lazy(() => import('../pages/employee/Manage_Groups')),
    Manage_Workplaces: React.lazy(() => import('../pages/employee/Manage_Workplaces')),
    Manage_Grades: React.lazy(() => import('../pages/employee/Manage_Grades')),
    Manage_Nationality: React.lazy(() => import('../pages/employee/Manage_Nationality')),
    Manage_Assettype: React.lazy(() => import('../pages/employee/Manage_Assettype')),
    Manage_Shift: React.lazy(() => import('../pages/employee/Manage_Shift')),
    Manage_Doctype: React.lazy(() => import('../pages/employee/Manage_Doctype')),
    Manage_Designation: React.lazy(() => import('../pages/employee/Manage_Designation')),
    Manage_Department: React.lazy(() => import('../pages/employee/Manage_Department')),
    Manage_Relations: React.lazy(() => import('../pages/employee/Manage_Relations')),
    Manage_Occupations: React.lazy(() => import('../pages/employee/Manage_Occupations')),
    // suppliers
    AddSupplier: React.lazy(() => import('../pages/Supplier/AddSupplier')),
    SupplierAllVoucherList: React.lazy(() => import('../pages/Supplier/SupplierAllVoucherList')),
    SupplierList: React.lazy(() => import('../pages/Supplier/SupplierList')),
    EditSupplier: React.lazy(() => import('../pages/Supplier/EditSupplier')),
    SetSupplierId: React.lazy(() => import('../pages/Supplier/SetSupplierId')),
    SupplierGroup: React.lazy(() => import('../pages/Supplier/SupplierGroup')),
    MultipleSupplierUpdate: React.lazy(() => import('../pages/Supplier/MultipleSupplierUpdate')),
    MultipleSupplierAdd: React.lazy(() => import('../pages/Supplier/MultipleSupplierAdd')),
    ImportSupplierExcel: React.lazy(() => import('../pages/Supplier/ImportSupplierExcel')),
    InactiveSupplierList: React.lazy(() => import('../pages/Supplier/InactiveSupplierList')),
    AddSupplierOpeningBalance: React.lazy(() => import('../pages/Supplier/AddSupplierOpeningBalance')),
    SupplierPostVoucherList: React.lazy(() => import('../pages/Supplier/SupplierPostVoucherList')),

    // suppliers-reports
    SupplierPaymentRpt: React.lazy(() => import('../pages/supplier-reports/SupplierPaymentRpt')),
    SupplierLedger: React.lazy(() => import('../pages/supplier-reports/SupplierLedger')),
    SupplierTable: React.lazy(() => import('../pages/supplier-reports/SupplierTable')),
    SupplierBalanceRep: React.lazy(() => import('../pages/supplier-reports/SupplierBalanceRep')),
    PayablesAging: React.lazy(() => import('../pages/supplier-reports/PayablesAging')),
    DebitNoteSummary: React.lazy(() => import('../pages/supplier-reports/DebitNoteSummary')),
    DebitNoteDetails: React.lazy(() => import('../pages/supplier-reports/DebitNoteDetails')),
    CreditNoteSummary: React.lazy(() => import('../pages/supplier-reports/CreditNoteSummary')),
    CreditNoteDetails: React.lazy(() => import('../pages/supplier-reports/CreditNoteDetails')),
    ServicesBillSummary: React.lazy(() => import('../pages/supplier-reports/ServicesBillSummary')),
    ServicesBillDetails: React.lazy(() => import('../pages/supplier-reports/ServicesBillDetails')),

    // Supplier Analysis
    ItemWisePurchase: React.lazy(() => import('../pages/purchase-analysis/ItemWisePurchase')),
    SupplierWisePurchase: React.lazy(() => import('../pages/purchase-analysis/SupplierWisePurchase')),
    PurchaseSummary: React.lazy(() => import('../pages/purchase-analysis/PurchaseSummary')),
    PurchasePayment: React.lazy(() => import('../pages/purchase-analysis/PurchasePayment')),
    PurchaseOrderSummary: React.lazy(() => import('../pages/purchase-analysis/PurchaseOrderSummary')),
    PurchaseReturnSummary: React.lazy(() => import('../pages/purchase-analysis/PurchaseReturnSummary')),
    GrnSummary: React.lazy(() => import('../pages/purchase-analysis/GrnSummary')),
    PurchaseDetails: React.lazy(() => import('../pages/purchase-analysis/PurchaseDetails')),
    PurchaseOrderDetails: React.lazy(() => import('../pages/purchase-analysis/PurchaseOrderDetails')),
    PurchaseReturnDetails: React.lazy(() => import('../pages/purchase-analysis/PurchaseReturnDetails')),
    GrnDetails: React.lazy(() => import('../pages/purchase-analysis/GrnDetails')),

    // Purchase
    addPurchase: React.lazy(() => import('../pages/purchase/addPurchase')),
    AddGRN: React.lazy(() => import('../pages/purchase/AddGRN')),
    GRNList: React.lazy(() => import('../pages/purchase/GRNList')),
    EditGRN: React.lazy(() => import('../pages/purchase/EditGRN')),
    PurchaseOrderList: React.lazy(() => import('../pages/purchase/PurchaseOrderList')),
    AddPurchaseOrder: React.lazy(() => import('../pages/purchase/AddPurchaseOrder')),
    AddPurchaseReturn: React.lazy(() => import('../pages/purchase/AddPurchaseReturn')),
    EditPurchaseInv: React.lazy(() => import('../pages/purchase/EditPurchaseInv')),
    EditPurchaseOrder: React.lazy(() => import('../pages/purchase/EditPurchaseOrder')),
    SupplierPayment: React.lazy(() => import('../pages/purchase/SupplierPayment')),
    EditSupplierPayment: React.lazy(() => import('../pages/purchase/EditSupplierPayment')),
    SupplierPaymentList: React.lazy(() => import('../pages/purchase/SupplierPaymentList')),
    PurchaseInv_List: React.lazy(() => import('../pages/purchase/PurchaseInv_List')),
    PurchaseReturnList: React.lazy(() => import('../pages/purchase/PurchaseReturnList')),
    EditPurchaseReturn: React.lazy(() => import('../pages/purchase/EditPurchaseReturn')),
    PendingPurchaseOrders: React.lazy(() => import('../pages/purchase/PendingPurchaseOrders')),
    PendingGRN: React.lazy(() => import('../pages/purchase/PendingGRN')),
    ClosePendingOrders: React.lazy(() => import('../pages/purchase/ClosePendingOrders')),
    ClosedPurchaseOrders: React.lazy(() => import('../pages/purchase/ClosedPurchaseOrders')),

    // Ledger
    GeneralLedger: React.lazy(() => import('../pages/Ledger/Reports/GeneralLedger')),
    Daybook: React.lazy(() => import('../pages/Ledger/Reports/Daybook')),
    Daybook2: React.lazy(() => import('../pages/Ledger/Reports/Daybook2')),
    Cashbook: React.lazy(() => import('../pages/Ledger/Reports/Cashbook')),
    Bankbook: React.lazy(() => import('../pages/Ledger/Reports/Bankbook')),
    AccountOpeningBal: React.lazy(() => import('../pages/Ledger/Setups/AccountOpeningBal')),
    ManageAccountType: React.lazy(() => import('../pages/Ledger/Setups/ManageAccountType')),
    AccountsPrintVoucherList: React.lazy(() => import('../pages/Ledger/Setups/AccountsPrintVoucherList')),
    AddLedgerAccount: React.lazy(() => import('../pages/Ledger/ChartOfAccounts/AddLedgerAccount')),
    ManageDetailAcount: React.lazy(() => import('../pages/Ledger/ChartOfAccounts/ManageDetailAcount')),
    InactiveAccounts: React.lazy(() => import('../pages/Ledger/ChartOfAccounts/InactiveAccounts')),
    InactiveGroupAcount: React.lazy(() => import('../pages/Ledger/ChartOfAccounts/InactiveGroupAcount')),
    AccountList: React.lazy(() => import('../pages/Ledger/ChartOfAccounts/AccountList')),
    AccountBalanceSummary: React.lazy(() => import('../pages/Ledger/Reports/AccountBalanceSummary')),
    TrialBalance: React.lazy(() => import('../pages/Ledger/Reports/TrialBalance')),
    ProfitandLoss: React.lazy(() => import('../pages/Ledger/Reports/ProfitandLoss')),
    BusinessOverview: React.lazy(() => import('../pages/Ledger/BusinessOverview')),

    // Jounral Entries

    // Journal Voucher
    JournalVoucher: React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher')),
    JournalVoucher_List: React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_List')),
    JournalVoucher_edit: React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_edit')),
    JournalVoucher_Summary: React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_Summary')),

    // Expense Voucher
    ExpenseVoucher: React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher')),
    ExpenseVoucher_List: React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_List')),
    ExpenseVoucher_edit: React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_edit')),
    ExpenseVoucher_Summary: React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_Summary')),

    AccountsPostVoucherList: React.lazy(() => import('../pages/Ledger/Setups/AccountsPostVoucherList')),

    // Cash Receipt
    CashReceipt: React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt')),
    CashReceipt_edit: React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_edit')),
    CashReceipt_List: React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_List')),
    CashReceipt_Summary: React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_Summary')),

    JournalVoucher_Details: React.lazy(() => import('../pages/Ledger/JournalEntries/JournalVoucher_Details')),
    ExpenseVoucher_Details: React.lazy(() => import('../pages/Ledger/JournalEntries/ExpenseVoucher_Details')),
    BankReceipt_Details: React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_Details')),
    BankPayment_Details: React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_Details')),
    CashReceipt_Details: React.lazy(() => import('../pages/Ledger/JournalEntries/CashReceipt_Details')),
    CashPayment_Details: React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_Details')),

    // Cash Payments
    CashPayment: React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment')),
    CashPayment_edit: React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_edit')),
    CashPayment_List: React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_List')),
    CashPayment_Summary: React.lazy(() => import('../pages/Ledger/JournalEntries/CashPayment_Summary')),

    // Bank Receipt
    BankReceipt: React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt')),
    BankReceipt_edit: React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_edit')),
    BankReceipt_List: React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_List')),
    BankReceipt_Summary: React.lazy(() => import('../pages/Ledger/JournalEntries/BankReceipt_Summary')),

    // Bank Payment
    BankPayment: React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment')),
    BankPayment_edit: React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_edit')),
    BankPayment_List: React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_List')),
    BankPayment_Summary: React.lazy(() => import('../pages/Ledger/JournalEntries/BankPayment_Summary')),

    // bank components
    ChequeReceipt: React.lazy(() => import('../pages/bank/checkReceipt/ChequeReceipt')),
    ChequeEncashList: React.lazy(() => import('../pages/bank/checkReceipt/ChequeEncashList')),
    ChequeRefundList: React.lazy(() => import('../pages/bank/checkReceipt/ChequeRefundList')),
    EditChequeRefund: React.lazy(() => import('../pages/bank/checkReceipt/EditChequeRefund')),
    ChequeRefund: React.lazy(() => import('../pages/bank/checkReceipt/ChequeRefund')),
    chequeEncash: React.lazy(() => import('../pages/bank/checkReceipt/chequeEncash')),
    ChequeReceiptList: React.lazy(() => import('../pages/bank/checkReceipt/ChequeReceiptList')),
    EditChequeReceipt: React.lazy(() => import('../pages/bank/checkReceipt/EditChequeReceipt')),
    ChequeEncashEdit: React.lazy(() => import('../pages/bank/checkReceipt/ChequeEncashEdit')),
    EditChequeReturn: React.lazy(() => import('../pages/bank/checkReceipt/EditChequeReturn')),
    ChequeReturn: React.lazy(() => import('../pages/bank/checkReceipt/ChequeReturn')),
    ChequeReturnList: React.lazy(() => import('../pages/bank/checkReceipt/ChequeReturnList')),
    chequeTransfer: React.lazy(() => import('../pages/bank/checkReceipt/chequeTransfer')),
    EditChequeTransfer: React.lazy(() => import('../pages/bank/checkReceipt/EditChequeTransfer')),
    ChequeTransferList: React.lazy(() => import('../pages/bank/checkReceipt/ChequeTransferList')),
    ChequePayment: React.lazy(() => import('../pages/bank/checkpayment/ChequePayment')),
    ChequePaymentList: React.lazy(() => import('../pages/bank/checkpayment/ChequePaymentList')),
    EditChequePayment: React.lazy(() => import('../pages/bank/checkpayment/EditChequePayment')),
    ReceiptSummary: React.lazy(() => import('../pages/bank/reports/ReceiptSummary')),
    ReceiptDetail: React.lazy(() => import('../pages/bank/reports/ReceiptDetail')),
    EncashSummary: React.lazy(() => import('../pages/bank/reports/EncashSummary')),
    EncashDetail: React.lazy(() => import('../pages/bank/reports/EncashDetail')),
    ChequeTransferSummary: React.lazy(() => import('../pages/bank/reports/ChequeTransferSummary')),
    ChequeTransferDetail: React.lazy(() => import('../pages/bank/reports/ChequeTransferDetail')),
    ReturnSummary: React.lazy(() => import('../pages/bank/reports/ReturnSummary')),
    ReturnDetail: React.lazy(() => import('../pages/bank/reports/ReturnDetail')),
    RefundSummary: React.lazy(() => import('../pages/bank/reports/RefundSummary')),
    RefundDetail: React.lazy(() => import('../pages/bank/reports/RefundDetail')),
    PaymentSummary: React.lazy(() => import('../pages/bank/reports/PaymentSummary')),
    PaymentDetail: React.lazy(() => import('../pages/bank/reports/PaymentDetail')),
    PaymentRetSummary: React.lazy(() => import('../pages/bank/reports/PaymentRetSummary')),
    PaymentRetDetail: React.lazy(() => import('../pages/bank/reports/PaymentRetDetail')),
    // Bank Setup
    OutstandingCheques: React.lazy(() => import('../pages/bank/setup/OutstandingCheques')),
    ClearedCheques: React.lazy(() => import('../pages/bank/setup/ClearedCheques')),
    PostChequePayments: React.lazy(() => import('../pages/bank/setup/PostChequePayments')),
    ManageBanks: React.lazy(() => import('../pages/bank/setup/ManageBanks')),

    Pendingchp: React.lazy(() => import('../pages/bank/setup/Pendingchp')),
    Clearedchp: React.lazy(() => import('../pages/bank/setup/Clearedchp')),
    CancelCheque: React.lazy(() => import('../pages/bank/setup/CancelCheque')),

    // customers payments
    AddCustPay: React.lazy(() => import('../pages/cusPayments/addCustPay')),
    CustomerRec_list: React.lazy(() => import('../pages/cusPayments/CustomerRec_list')),
    CustPay_Edit: React.lazy(() => import('../pages/cusPayments/CustPay_Edit')),

    // credit note
    CreditNote: React.lazy(() => import('../pages/cusPayments/CreditNote')),
    DebitNote: React.lazy(() => import('../pages/cusPayments/DebitNote')),
    CreditNoteList: React.lazy(() => import('../pages/cusPayments/CreditNoteList')),
    DebitNoteList: React.lazy(() => import('../pages/cusPayments/DebitNoteList')),
    EditCreditNote: React.lazy(() => import('../pages/cusPayments/EditCreditNote')),
    EditDebitNote: React.lazy(() => import('../pages/cusPayments/EditDebitNote')),
    SupplierCreditNote: React.lazy(() => import('../pages/purchase/SupplierCreditNote')),
    AddServicesBill: React.lazy(() => import('../pages/purchase/AddServicesBill')),
    SupplierDebitNote: React.lazy(() => import('../pages/purchase/SupplierDebitNote')),
    SupplierCreditNoteList: React.lazy(() => import('../pages/purchase/SupplierCreditNoteList')),
    ServicesBillList: React.lazy(() => import('../pages/purchase/ServicesBillList')),
    EditServicesBill: React.lazy(() => import('../pages/purchase/EditServicesBill')),
    SupplierDebitNoteList: React.lazy(() => import('../pages/purchase/SupplierDebitNoteList')),
    SupplierEditCreditNote: React.lazy(() => import('../pages/purchase/SupplierEditCreditNote')),
    SupplierEditDebitNote: React.lazy(() => import('../pages/purchase/SupplierEditDebitNote')),

    // Flow Charts

    Flowchart: React.lazy(() => import('../pages/Flowchart')),

    // lamding
    Landing: React.lazy(() => import('../pages/Landing')),
};
const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {
    if (Component) {
        return (
            <Suspense fallback={loading()}>
                <Component />
            </Suspense>
        );
    }
};

const AllRoutes = () => {
    const { appSelector } = useRedux();
    const { layout, user } = appSelector((state) => ({
        layout: state.Layout,
        user: state.Auth.user,
    }));

    let [getRoutes, setRoutes] = useState([]);
    let [getRole, setRole] = useState(null);
    useEffect(() => {
        if (user) {
            if (user.get_routes) {
                setRoutes(user.get_routes.original.menus);
                setRole({ role: user.get_routes.original.role, id: user.get_routes.original.userid });
            }
        }
    }, [user]);

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    let Layout = getLayout();

    // Define the base routes
    let protectedRoutes = [
        { path: '/', element: <Root /> },
        {
            path: '/',
            element: <DefaultLayout />,
            children: [
                { path: 'auth/login', element: <LoadComponent component={componentMapping['Login']} /> },
                getRoutes.length > 0 && {
                    path: '*',
                    element: <LoadComponent component={componentMapping['Error404']} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={getRole?.role} id={getRole?.id} component={Layout} />,
            children: [
                {
                    path: '/dashboard',
                    element: <LoadComponent component={componentMapping['DashBoard1']} />,
                },
            ],
        },
    ];

    // Dynamically generate and add routes
    getRoutes.forEach((route) => {
        protectedRoutes[2].children.push({
            path: route.path,
            element: <LoadComponent component={componentMapping[route.component]} />,
        });
    });

    return useRoutes(protectedRoutes);
};

export { AllRoutes };
