import React, { Suspense, useEffect, useState } from 'react';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import axios from "axios";
import MainLoader from '../../components/MainLoader';
import {
    changeLayout,
    changeLayoutColor,
    changeTopbarTheme
    ,changeFooterType,
} from '../../redux/actions';
// constants
import { LayoutTypes, TopbarTheme } from '../../constants/layout';

// hooks
import { useRedux ,useViewport} from '../../hooks';

// utils
import { changeBodyAttribute } from '../../utils';
import ShortcutLinks from '../../pages/dashboards/DashBoard1/ShortcutLinks';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('../Topbar'));
const Navbar = React.lazy(() => import('./Navbar'));
const Footer = React.lazy(() => import('../Footer'));
const RightSidebar = React.lazy(() => import('../RightSidebar'));

const loading = () => <div className="text-center"></div>;

type HorizontalLayoutProps = {
    children?: any;
};

const HorizontalLayout = ({ children }: HorizontalLayoutProps) => {
    const { dispatch, appSelector } = useRedux();
    const { width } = useViewport();
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    let [themeItems,setThemeItems] = useState(null);
    const { layoutColor, layoutWidth, menuPosition,toggleFooter, topbarTheme, isOpenRightSideBar, pageTitle } = appSelector(
        (state) => ({
            layoutColor: state.Layout.layoutColor,
            layoutWidth: state.Layout.layoutWidth,
            menuPosition: state.Layout.menuPosition,
            toggleFooter: state.Layout.toggleFooter,
            topbarTheme: state.Layout.topbarTheme,
            isOpenRightSideBar: state.Layout.isOpenRightSideBar,
            pageTitle: state.PageTitle.pageTitle,
        })
    );

    /*
    layout defaults
    */
    useEffect(() => {
        changeBodyAttribute('data-layout-mode', LayoutTypes.LAYOUT_HORIZONTAL);
        dispatch(changeTopbarTheme(TopbarTheme.TOPBAR_THEME_DARK));
    }, [dispatch]);

    useEffect(() => {
        changeBodyAttribute('data-layout-color', layoutColor);
    }, [layoutColor]);
    useEffect(() => {
        changeBodyAttribute('scroll-footer', toggleFooter);
    }, [toggleFooter]);

    useEffect(() => {
        changeBodyAttribute('data-layout-size', layoutWidth);
    }, [layoutWidth]);

    useEffect(() => {
        changeBodyAttribute('data-leftbar-position', menuPosition);
    }, [menuPosition]);

    useEffect(() => {
        changeBodyAttribute('data-topbar-color', topbarTheme);
    }, [topbarTheme]);
    
    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened(!isMenuOpened);
        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };
  const [loadings, setLoading] = useState(false);
    const [menusItems, setMenuItems] = useState([]);
    const [companyInfo, setCompanyInfo] = useState(null);
    useEffect(()=>{
        setLoading(true)
        let user = sessionStorage.getItem("comp_Info")
     let covertJson = JSON.parse(user);
     if(!covertJson) return;
     setCompanyInfo(covertJson);
        getMenusData();
         
    },[])
     const getMenusData = async()=>{
        await axios.get('/get-menus').then((res)=>{
            setMenuItems(res.data.menus);
            getThemeData();
        })
     }

      const getThemeData = async()=>{
        await axios.get('/get-theme-customization').then((res)=>{
            setThemeItems(res.data.customization)
             dispatch(changeLayout(res.data.customization.layout));
         dispatch(changeLayoutColor(res.data.customization.colorscheme));
         dispatch(changeTopbarTheme(res.data.customization.topbarTheme));
         dispatch(changeFooterType(res.data.customization.footerType));
            setLoading(false)
        })
     }
     const [dashbaordRights, setDashbaordRights] = useState([]);
const [getMenu, setMenu] = useState([])
    const [getRights, setRights] = useState(null);
    const [getIconType, setIconType] = useState(null);
    const [getMobileRights, setMobileRights] = useState(null);

    const getRightsData = async () => {
        let user = sessionStorage.getItem('user_Information');
        let id = JSON.parse(user)?.id;
        await axios.get(`/get-shortcutbar-rights/${id}`).then((res) => {
            setRights(res.data.shortcutRights);
            setIconType(res.data.icontype);
            setMobileRights(res.data.mobileshortcutRights);
        });
    };
// const buttonItems = [
        
//   {
//       tooltip: "Add Invoice",
//       url: "si/add",
//       name: 'SI',
//       icon: "ti-receipt",
//   },
//   {
//       tooltip: "Add Purchase",
//       url: "purchase/addPurchase",
//       name: 'PI',
//       icon: "fe-shopping-cart",
//   },
//   {
//       tooltip: "Add Customer Receipt",
//       url: "pay-Receipt/add",
//       name: 'PMT',
//       icon: "fas fa-file-invoice-dollar",
//   },
//   {
//       tooltip: "Add Supplier Payment",
//       url: "supplier-payment/add",
//       icon: "fas fa-dollar-sign",
//       name: 'SP',
//   },

//   {
//       tooltip: "Customer Ledger",
//       url: "customers/ledger",
//       icon: "fe-file-text",
//       name: 'CL',
//   },
//   {
//       tooltip: "Supplier Ledger",
//       url: "suppliers/ledger",
//       icon: "fe-file-text",
//       name: 'VL',
//   },
//   {
//       tooltip: "Cash Payment",
//       url: "ledger/cashpayment/add",
//       icon: "mdi mdi-18px mdi-book-plus-multiple",
//       name: 'CPV',
//   },
//   {
//       tooltip: "Cash Receipt",
//       url: "ledger/cashreceipt/add",
//       icon: "mdi mdi-18px mdi-book-plus-multiple",
//       name: 'CRV',
//   },
//   {
//       tooltip: "Account Ledger",
//       url: "ledger/generalledger",
//       icon: "fe-file-text",
//       name: 'AL',
//   },
//   {
//       tooltip: "Day Book",
//       url: "ledger/daybook",
//       icon: "mdi mdi-book-open-page-variant-outline",
//       name: 'DB',
//   },
//   {
//       tooltip: "Cash Book",
//       url: "ledger/cashbook",
//       icon: "mdi mdi-book-open-page-variant-outline",
//       name: 'CB',
//   },
// ];

    useEffect(() => {
        getDashboardRights();
        getMenuData();
      }, [])
      const getDashboardRights = async () => {
        let user = sessionStorage.getItem('user_Information')
        let id = JSON.parse(user)?.id
        await axios.get(`/get-dashboard-rights/${id}`).then((res) => {
            setDashbaordRights(res.data.dashboardRights)
        });
      };
      const getMenuData = async () => {
      
        await axios.get('/get-menus').then((res) => {
            setMenu(res.data.menus)
        })
      }
    //   const filteredButtonItems = buttonItems.filter((button) =>
    //     getMenu.some((route) =>route.children?.some((menu) =>menu.children?.some((main) =>main.url?.includes(button.url))))
    //   );
 if(!loadings)
    return (
        <>
            <div id="wrapper">
                <Suspense fallback={loading()}>
                    <Topbar openLeftMenuCallBack={openMenu} containerClass="container-fluid" branchName={pageTitle.title} />
                </Suspense>

                <Suspense fallback={loading()}>
                    <Navbar isMenuOpened={isMenuOpened} menusItems={menusItems} />
                </Suspense>
                <div className="content-page">
                    <div className="content">
                        <div>
                            <Row>
                                <Col xs={12}>
                                    <div className="page-title-box">
                                        <div className="page-title-right">
                                            <Breadcrumb className="m-0">
                                                {(pageTitle.breadCrumbItems || []).map(
                                                    (
                                                        item: {
                                                            label: string;
                                                            path: string;
                                                            active?: boolean;
                                                        },
                                                        index: React.Key
                                                    ) => {
                                                        return item.active ? (
                                                            <Breadcrumb.Item active key={index.toString()}>
                                                                {item.label}
                                                            </Breadcrumb.Item>
                                                        ) : (
                                                            <Breadcrumb.Item key={index.toString()} href={item.path}>
                                                                {item.label}
                                                            </Breadcrumb.Item>
                                                        );
                                                    }
                                                )}
                                            </Breadcrumb>
                                        </div>
                                        <h4 className="page-title">{pageTitle.title}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <ShortcutLinks dashbaordRights={dashbaordRights} filteredButtonItems={width<=786 ? getMobileRights: getRights} iconType={getIconType}/>                    
                            <Outlet />
                        </div>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>

                    {isOpenRightSideBar && (
                        <Suspense fallback={loading()}>
                            <RightSidebar themeItems={themeItems} getThemeData={getThemeData}/>
                        </Suspense>
                    )}
                </div>
            </div>
        </>
    );
    return (<>
      <MainLoader useInfo={companyInfo}/></>);
};

export default HorizontalLayout;
