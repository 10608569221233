import { APICore } from './apiCore';
import axios from 'axios';

const api = new APICore();

function addBOM_api(params: {
    entrydate: string;
    amount: string;
    itemid: string;
    costnumber: string;
    quantity: string;
    costdetdata: object;
}) {
    console.log('employee add---');
    const baseUrl = '/add-billofmaterial';
    return api.create(`${baseUrl}`, params);
}
function addPRD_api(params: {
    entrydate: string;
    description: string;
    amount: string;
    peamount: string;
    productiondata: object;
}) {
    console.log('employee add---');
    const baseUrl = '/add-production';
    return api.create(`${baseUrl}`, params);
}

function editPRD_api(params: {
    id: string;
    entrydate: string;
    description: string;
    amount: string;
    peamount: string;
    productiondata: object;
}) {
    const baseUrl = `/update-production/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function manageProcess_Api(params: { name: string }) {
    console.log('employee add---');
    const baseUrl = '/save-process';
    return api.create(`${baseUrl}`, params);
}
function manufacProcess_Api(params: { proecessdata: object }) {
    console.log('employee add---');
    const baseUrl = '/save-process-rates';
    return api.create(`${baseUrl}`, params);
}
axios.interceptors.response.use((response) => {
    return response;
});

export { addBOM_api, manageProcess_Api, manufacProcess_Api, addPRD_api, editPRD_api };
