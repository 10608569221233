// constants
import { ProcessActionTypes } from './constants';

export type ProcessActionType = {
    type:
        | ProcessActionTypes.API_RESPONSE_SUCCESS
        | ProcessActionTypes.API_RESPONSE_ERROR
        | ProcessActionTypes.RESET
        | ProcessActionTypes.EDIT_PRD
        | ProcessActionTypes.MANUFAC_PROCESS
        | ProcessActionTypes.ADD_PRD
        | ProcessActionTypes.MANAGE_PROCESS
        | ProcessActionTypes.ADD_BOM;

    payload: {} | string;
};

type EmpData = {
    entrydate: string;
    amount: string;
    itemid: string;
    costnumber: string;
    costdetdata: object;
};

// common success
export const processApiResponseSuccess = (actionType: string, data: EmpData | {}): ProcessActionType => ({
    type: ProcessActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const processApiResponseError = (actionType: string, error: string): ProcessActionType => ({
    type: ProcessActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addBOM = (
    entrydate: string,
    amount: string,
    itemid: string,
    costnumber: string,
    quantity:string,
    costdetdata: object,
): ProcessActionType => ({
    type: ProcessActionTypes.ADD_BOM,
    payload: {
        entrydate,
        amount,
        itemid,
        costnumber,
        quantity,
        costdetdata,
        
    },
});
export const addPRD = (
    entrydate: string,
    description: string,
    amount: string,
    peamount: string,
    productiondata: object,
): ProcessActionType => ({
    type: ProcessActionTypes.ADD_PRD,
    payload: {
        entrydate,
        description,
        amount,
        peamount,
        productiondata,
        
    },
});
export const editPRD = (
    id:string,
    entrydate: string,
    description: string,
    amount: string,
    peamount: string,
    productiondata: object,
): ProcessActionType => ({
    type: ProcessActionTypes.EDIT_PRD,
    payload: {
        id,
        entrydate,
        description,
        amount,
        peamount,
        productiondata,
        
    },
});
export const manuProcess = (
    proecessdata: object,
): ProcessActionType => ({
    type: ProcessActionTypes.MANUFAC_PROCESS,
    payload: {
        proecessdata,
    },
});


export const manageProcess = (
    name: string,
): ProcessActionType => ({
    type: ProcessActionTypes.MANAGE_PROCESS,
    payload: {
        name,
        
    },
});


export const resetBOM = (): ProcessActionType => ({
    type: ProcessActionTypes.RESET,
    payload: {},
});
