import { BankActionTypes } from './constants';

export const BankResponseSuccess = (actionType, data) => ({
    type: BankActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
export const BankResponseError = (actionType, error) => ({
    type: BankActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, error },
});
export const updCheckReceipt = (id, entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.UPD_CheckReceipt,
    payload: {
        id,
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});

export const manageBanksAcc = (
    accountid: string,
    bankid: string,
    name: string,
    acctnumber: string,
    iban: string,
    branch: string,
    branchcode: string,
    address: string,
    detaildata: object
) => ({
    type: BankActionTypes.ADD_BANK_ACC,
    payload: { accountid, bankid, name, acctnumber, iban, branch, branchcode, address, detaildata },
});

export const updChqueTransfer = (id, entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.UPD_CHQUETRANFER,
    payload: {
        id,
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});

export const updChqueTransferReturn = (id, entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.UPD_CHQUETRANFERRETURN,
    payload: {
        id,
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});

export const updEncash = (id, entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.UPD_ENCASH,
    payload: {
        id,
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const updRefund = (id, entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.UPD_RFUND,
    payload: {
        id,
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const updChequePayReturn = (id, entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.UPD_CHEQUEPAYRETURN,
    payload: {
        id,
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const manageBanks = (name: string) => ({
    type: BankActionTypes.ADD_BANK,
    payload: { name },
});

export const addCheckReceipt = (entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.Add_CheckReceipt,
    payload: {
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const addChequeTransfer = (entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.ADD_CHEQUETRANSFER,
    payload: {
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const addChequeTransferReturn = (entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.ADD_CHEQUETRANSFERRETURN,
    payload: {
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const cancelCheque = (bankid, booknumber, chequenumber, description) => ({
    type: BankActionTypes.Add_CANCEL,
    payload: {
        bankid,
        booknumber,
        chequenumber,
        description,
    },
});
export const addEncash = (entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.Add_ENCASH,
    payload: {
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const addRefund = (entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.Add_REFUND,
    payload: {
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const addChequePayReturn = (entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.ADD_CHEQUEPAYRETURN,
    payload: {
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});
export const updCheckPayment = (id, entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.UPD_CheckPayment,
    payload: {
        id,
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});

export const addCheckPayment = (entrydate, description, totalamount, voucherdetdata) => ({
    type: BankActionTypes.Add_CheckPayment,
    payload: {
        entrydate,
        description,
        totalamount,
        voucherdetdata,
    },
});

export const resetBank = () => ({
    type: BankActionTypes.RESET,
    payload: {},
});
