/* Layout types and other constants */

export enum LayoutTypes {
    LAYOUT_VERTICAL = 'vertical',
    LAYOUT_HORIZONTAL = 'horizontal',
}

export enum LayoutColor {
    LAYOUT_COLOR_LIGHT = 'light',
    LAYOUT_COLOR_DARK = 'dark',
}

export enum LayoutWidth {
    LAYOUT_WIDTH_FLUID = 'fluid',
    LAYOUT_WIDTH_BOXED = 'boxed',
}

export enum MenuPositions {
    MENU_POSITION_FIXED = 'fixed',
    MENU_POSITION_SCROLLABLE = 'scrollable',
}

export enum SideBarTheme {
    LEFT_SIDEBAR_THEME_YELLOW = 'yellow',
    LEFT_SIDEBAR_THEME_LIGHT = 'light',
    LEFT_SIDEBAR_THEME_DARK = 'dark',
    LEFT_SIDEBAR_THEME_BRAND = 'brand',
    LEFT_SIDEBAR_THEME_GREEN = 'green',
}

export enum SideBarTypes {
    LEFT_SIDEBAR_TYPE_DEFAULT = 'default',
    LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed',
    LEFT_SIDEBAR_TYPE_COMPACT = 'compact',
}

export enum TopbarTheme {
    TOPBAR_THEME_LIGHT = 'light',
    TOPBAR_THEME_DARK = 'dark',
    TOPBAR_THEME_BLUE = 'gradient',
    TOPBAR_THEME_YELLOW = 'yellow',
    TOPBAR_THEME_GREEN = 'green',
}

export enum FooterType {
    FOOTER_TYPE_SHOW = 'show',
    FOOTER_TYPE_HIDE = 'hide',
}
export enum shortcutbar {
    SHORTCUT_TYPE_SHOW = 'show',
    SHORTCUT_TYPE_HIDE = 'hide',
}
export enum shortcutbarmobile {
    SHORTCUT_TYPE_MOBILE_SHOW = 'show',
    SHORTCUT_TYPE_MOBILE_HIDE = 'hide',
}

