// apicore
import { APICore } from '../../helpers/api/apiCore';

// constants
import { ProcessActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    addedBOM: null,
    addedPRD: null,
    updatedPRD: null,
    manufacProcess:null,
    manageProcessAdded: null,
    loading: false,
    modalLoading: false,
    error:null,
};

type EmpData = {
    entrydate: string;
    amount: string;
    itemid: string;
    costnumber: string;
    costdetdata: object;
};


type ProcessActionType = {
    type:
        | ProcessActionTypes.API_RESPONSE_SUCCESS
        | ProcessActionTypes.API_RESPONSE_ERROR
        | ProcessActionTypes.ADD_PRD
        | ProcessActionTypes.EDIT_PRD
        | ProcessActionTypes.ADD_BOM
        | ProcessActionTypes.MANUFAC_PROCESS
        | ProcessActionTypes.MANAGE_PROCESS
        | ProcessActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: EmpData | null;
        loading: boolean;
        error?: string;
    };
};

type State = {
    EmpAdded?: EmpData | null;
    loading?: boolean;
    value?: boolean;
};

const Employee = (state: State = INIT_STATE, action: ProcessActionType): any => {
    switch (action.type) {
        case ProcessActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProcessActionTypes.ADD_BOM: {
                    return {
                        ...state,
                        addedBOM: action.payload,
                        loading: false,
                    };
                }
                case ProcessActionTypes.ADD_PRD: {
                    return {
                        ...state,
                        addedPRD: action.payload,
                        loading: false,
                    };
                }
                case ProcessActionTypes.EDIT_PRD: {
                    return {
                        ...state,
                        updatedPRD: action.payload,
                        loading: false,
                    };
                }
                case ProcessActionTypes.MANUFAC_PROCESS: {
                    return {
                        ...state,
                        manufacProcess: action.payload,
                        loading: false,
                    };
                }
                case ProcessActionTypes.MANAGE_PROCESS: {
                    return {
                        ...state,
                        manageProcessAdded: action.payload,
                        modalLoading: false,
                    };
                }
                
                default:
                    return { ...state };
            }

        case ProcessActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                
                case ProcessActionTypes.ADD_BOM: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedBOM: null,
                        loading: false,
                    };
                }
                case ProcessActionTypes.ADD_PRD: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        addedPRD: null,
                        loading: false,
                    };
                }
                case ProcessActionTypes.EDIT_PRD: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        updatedPRD: null,
                        loading: false,
                    };
                }
                case ProcessActionTypes.MANUFAC_PROCESS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        manufacProcess: null,
                        loading: false,
                    };
                }
                case ProcessActionTypes.MANAGE_PROCESS: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        manageProcessAdded: null,
                        modalLoading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ProcessActionTypes.ADD_BOM:
            return { ...state, loading: true, addedBOM: null };
        case ProcessActionTypes.ADD_PRD:
            return { ...state, loading: true, addedPRD: null };
        case ProcessActionTypes.EDIT_PRD:
            return { ...state, loading: true, updatedPRD: null };
        case ProcessActionTypes.MANUFAC_PROCESS:
            return { ...state, loading: true, manufacProcess: null };
        case ProcessActionTypes.MANAGE_PROCESS:
            return { ...state, modalLoading: true, manageProcessAdded: null };
        
        case ProcessActionTypes.RESET:
            return {
                ...state,
                loading: false,
                modalLoading: false,
                manageProcessAdded: null,
                error: null,
                manufacProcess: null,
                addedBOM: null,
                updatedPRD: null,
                addedPRD: null,
             };
        default:
            return { ...state };
    }
};

export default Employee;
