import { APICore } from './apiCore';
const api = new APICore();

function addCheque_Receipts(params) {
    const baseUrl = '/add-cheque-receipt';
    return api.create(`${baseUrl}`, params);
    
}
function add_enCash_API(params) {
    const baseUrl = '/add-cheque-encash';
    return api.create(`${baseUrl}`, params);
    
}
function add_BankAcc_API(params) {
    const baseUrl = '/add-bank-account';
    return api.create(`${baseUrl}`, params);
    
}
function add_Refund_API(params) {
    const baseUrl = '/add-cheque-refund';
    return api.create(`${baseUrl}`, params);
    
}
function add_ChequePayReturn_API(params) {
    const baseUrl = '/add-cheque-paymentret';
    return api.create(`${baseUrl}`, params);
    
}
function add_Cancel_API(params) {
    const baseUrl = '/add-cancel-cheque';
    return api.create(`${baseUrl}`, params);
    
}
function add_chequetranfer_API(params) {
    const baseUrl = '/add-cheque-transfer';
    return api.create(`${baseUrl}`, params);
    
}
function add_chequetranferReturn_API(params) {
    const baseUrl = '/add-cheque-transfer-return';
    return api.create(`${baseUrl}`, params);
    
}
function managebank_API(params: {name :string}) {
  console.log('Carrier added---');
  console.log(params)
  const baseUrl = `/add-bank`;
  return api.create(`${baseUrl}`, params);
}
function updCheque_Receipts(params) {
    const baseUrl = `/update-cheque-receipt/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function updCheque_Tranfer_api(params) {
    const baseUrl = `/update-cheque-transfer/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function upd_enCash_API(params) {
    const baseUrl = `/update-cheque-encash/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function upd_Refund_API(params) {
    const baseUrl = `/update-cheque-refund/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function upd_ChequePayReturn_API(params) {
    const baseUrl = `/update-cheque-paymentret/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function upd_chequetranferReturn_API(params) {
    const baseUrl = `/update-cheque-transfer-return/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}
function addCheque_Payments(params) {
    const baseUrl = '/add-cheque-payment';
    return api.create(`${baseUrl}`, params);
    
}
function updCheque_Payments(params) {
    const baseUrl = `/update-cheque-payment/${params.id}`;
    return api.update(`${baseUrl}`, params);
    
}

export { addCheque_Receipts,upd_ChequePayReturn_API,add_ChequePayReturn_API,upd_Refund_API,add_Refund_API,upd_chequetranferReturn_API,updCheque_Receipts,updCheque_Tranfer_api,
    addCheque_Payments,add_enCash_API,upd_enCash_API,add_BankAcc_API,add_chequetranfer_API,
    updCheque_Payments,managebank_API,add_Cancel_API,add_chequetranferReturn_API}