import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useViewport } from '../../../hooks';
function ShortcutLinks({ dashbaordRights, filteredButtonItems, iconType }) {
    const goto = useNavigate();
    let { width } = useViewport();
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    const isAllowed = dashbaordRights.some((right) => right.value === 28) && filteredButtonItems?.length !== 0;
    const colorMap = {
        'text-primary': '#0d6efd',
        'text-secondary': '#6c757d',
        'text-success': '#198754',
        'text-danger': '#dc3545',
        'text-warning': '#ffc107',
        'text-info': '#0dcaf0',
        'text-light': '#f8f9fa',
        'text-dark': '#212529',
    };

    return (
        <div>
            {isAllowed && (
                <div
                    className={`d-flex bg-light ps-3 mobile-scroll`} // Add the mobile-scroll class here
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        padding: '5px',
                        borderRadius: '30px',
                        marginBottom: '10px',
                    }}>
                    {iconType === 'byicon' &&
                        (filteredButtonItems || []).map(({ label, url, name, icon, color }, i) => (
                            <div className={`flex mx-1 ${color == null || color == '' ? 'text-info' : color}`} key={i}>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{label}</Tooltip>}>
                                    <i
                                        className={`${icon} me-1 cursor-pointer`}
                                        style={{ fontSize: '1.2rem' }}
                                        onClick={() => goto(`/${url}`)}></i>
                                </OverlayTrigger>
                            </div>
                        ))}

                    {iconType === 'byname' &&
                        (filteredButtonItems || []).map(({ label, url, name, icon, color }, i) => (
                            <span
                                className="d-flex flex-wrap"
                                style={{
                                    width: 'auto',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    border: `1px solid ${color == null || color == '' ? '#35b8e0' : colorMap[color]}`,
                                    // border: `1px solid #35b8e0`,
                                    color: color == null || color == '' ? '#35b8e0' : colorMap[color],
                                    // color: '#35b8e0',
                                    marginRight: '8px',
                                    marginTop: '5px',
                                    borderRadius: '29px',
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                }}>
                                <div
                                    onClick={() => goto(`/${url}`)}
                                    style={{
                                        // border: '1px solid #35b8e0',
                                        borderRadius: '20px',
                                        // padding: '4px',
                                        textAlign: 'center',
                                        color: color == null || color == '' ? '#35b8e0' : colorMap[color],
                                        // color: '#35b8e0',
                                        fontWeight: 'bold',
                                        // backgroundColor: '#35b8e0',
                                        // marginTop: '3px',
                                        cursor: 'pointer',
                                        // marginLeft: '17px',
                                        whiteSpace: 'nowrap',
                                    }}>
                                    &nbsp;{label}&nbsp;
                                </div>
                            </span>
                        ))}
                    {iconType === 'byabbreviation' &&
                        (filteredButtonItems || []).map(({ label, url, name, icon, color, abbreviation }, i) => (
                            <span
                                className="d-flex"
                                style={{
                                    marginTop: '5px',
                                }}>
                                <OverlayTrigger placement="top" overlay={<Tooltip>{label}</Tooltip>}>
                                    <div
                                        onClick={() => goto(`/${url}`)}
                                        style={{
                                            // border: '1px solid green',
                                            borderRadius: '18px',
                                            cursor: 'pointer',
                                            padding: '1px 9px 1px 11px',
                                            textAlign: 'center',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            backgroundColor: color == null || color == '' ? '#35b8e0' : colorMap[color],
                                            marginRight: '5px',
                                            marginLeft: '5px',
                                            whiteSpace: 'nowrap',
                                            letterSpacing: '1px',
                                        }}>
                                        {abbreviation}
                                    </div>
                                </OverlayTrigger>
                            </span>
                        ))}
                    {filteredButtonItems?.length != 0 ? (
                        <i
                            className="dripicons-information text-info cursor-pointer mt-1"
                            style={{ fontSize: '1.2rem', marginTop: '3px' }}
                            onClick={toggle}
                        />
                    ) : (
                        <></>
                    )}
                    {filteredButtonItems?.length != 0 ? (
                        <i
                            className="fe-settings noti-icon cursor-pointer ms-1"
                            style={{ fontSize: '1.2rem', marginTop: '3px' }}
                            onClick={() => goto('/admin/shortcutbarrights')}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            )}

            <Offcanvas show={show} onHide={toggle} style={{ borderRadius: '0px' }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="mb-0">User Guides</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        This shortcut bar contains various links for transaction forms, reports and idenetity forms for
                        easy access
                    </div>
                    <h5 className="mt-3">Guidelines</h5>
                    <ul className="ps-3">
                        <li>
                            Each icon represents a specific category of forms and reports, making it easier to navigate
                            different sections of the system efficiently.
                        </li>
                        <li>
                            These forms and reports help users create, manage, and track various business transactions,
                            financial records, and identity-related data.
                        </li>
                        <li>
                            The shortcut bar is designed for flexibility, allowing users to quickly access frequently
                            used forms and reports without navigating through multiple menus.
                        </li>
                        <li>
                            The appearance and number of links in the shortcut bar can be customized dynamically,
                            ensuring a personalized experience based on user preferences and workflow needs.
                        </li>
                        <li>
                            Users can modify the arrangement and selection of links to prioritize the most relevant
                            functions, improving overall efficiency and usability.
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}
export default ShortcutLinks;
